const meetingHelpers = {
    inviteText: (meetingId, meetingUrl) => {
        return `Please follow the steps below to join our ZOOM meeting:
        1. Go to ${meetingUrl}
        Kindly make sure you have your computer ready, as it will be a video enabled meeting.
        2. Please choose  "Join Audio by Computer" and connect with a headset for the audio connection. 
        Alternatively, dial-in via phone and enter the meeting room ID ${meetingId}
        The dial in numbers are as follows:
        USA
        +1 415 762 9988 (The Bay Area California)
        +1 646 568 7788 (NY)
        +1 646 558 8656 (NY)
        +1 408 638 0968 (The Bay Area California)
        
        UK
        +44 (0) 20 3695 0088
        
        Australia
        +61 (0) 2 8015 2088
        
        New Zealand
        +64 (0) 9 801 1188`;
    }
}

export default meetingHelpers;