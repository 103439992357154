import React from "react";

const FAQ = () => {

    return (
        <div className="faq-container">
            <div className="faq-header">
                <i className="fas fa-cog" /> FAQ
        </div>
            <div className="faq-list">
                <div className="faq-item">
                    <div className="faq-title">My Avatar in a meeting doesn’t have my picture, what’s up with that?</div>
                    <div className="faq-answer">
                        There could be two things going on here:
                    <ul>
                            <li>Make sure that you are logged into the zoom app on your computer/tablet/mobile device.</li>
                            <li>Make sure there is a picture set for your user profile in Zoom.</li>
                        </ul>
                    </div>
                </div>

                <div className="faq-item">
                    <div className="faq-title">What do the Link/Invite/Join buttons at the bottom of each meeting card do?</div>
                    <div className="faq-answer">
                        <ul>
                            <li>“Link” will copy the Zoom URL that you can share with someone to join a meeting</li>
                            <li>“Invite” will actually copy the entire Zoom meeting invitation, including call-in details that you can include in a calendar invite.</li>
                            <li>Join will open the Zoom URL to that meeting. </li>
                        </ul>
                    </div>
                </div>
                <div className="faq-item">
                    <div className="faq-title">How should I start a meeting?</div>
                    <div className="faq-answer">
                        Meetings should be started with the green “Start Meeting” button in the top right corner of the screen. This will give you options on how to describe your meeting, and if it’s private or not.
                    </div>
                </div>
                <div className="faq-item">
                    <div className="faq-title">What is the easiest way to find where Jen from Accounting is?</div>
                    <div className="faq-answer">
                        You have two options to easily find Jen.
                    <ul>
                            <li>You can click on the Teams tab at the top, look for accounting, and then hover over Jen to get the information card.  Or -</li>
                            <li>You can search for Jen’s name at the top of the page in the search bar.</li>
                        </ul>
                    </div>
                </div>
                <div className="faq-item">
                    <div className="faq-title">My Avatar shows I’m sleeping during working hours… I’m very much awake, what is happening?</div>
                    <div className="faq-answer">
                        Likely this is due to your Working Hours not being set to the correct hours on the Settings page.
                    </div>
                </div>

            </div>
        </div>
    );
}

export default FAQ;