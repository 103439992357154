import React from "react";
import { useLocation } from "react-router-dom";
import firebaseApi from "../../apis/firebase/firebaseApi";


const Auth = () => {
    let code = new URLSearchParams(useLocation().search).get("code");
    let clientId = new URLSearchParams(useLocation().search).get("state");
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        if (!code) return;
        
        fetch(`https://us-central1-powerfront-zoom.cloudfunctions.net/login`,
        {
            method: "POST",
            body: JSON.stringify({code: code, clientId: clientId}),
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(response => {
            console.log(response);
            if (response.status === 400 || response.status === 500) {
                setError(true);
                return response.json();
            } else {
                return response.json();
            }
        }).then(response => {
            if (response.code === 400 || response.code === 500) {
                if (response.message === "ACCOUNT_ACCESS_ERROR") {
                    window.location = "/account/authorize";
                }
            }
            if (response.authToken) {
                firebaseApi.login(response.authToken, () => {
                    //history.push(RouteEnum.HOME);
                });
            }
        }).catch(error => {
            setError(true);
            console.log(error);
        });
    }, [code, clientId])

    return <div className="layout">
        {
            error
                ? <span className="text-danger">An error occurred while attempting to log you in. Please <a href="/login">Try Again</a>.</span>
                : <span>Logging in ... be back in a sec</span>
        }
    </div>;
}

export default Auth;