import React from "react";

const Terms = () => {
    return (
        <div className="content-center">
            <h2>Coming Soon!</h2>
        </div>
    )
}

export default Terms;