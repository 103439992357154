import React from 'react';
import firebaseApi from '../apis/firebase/firebaseApi';
import { useHistory } from 'react-router-dom';
import RouteEnum from "../models/Enums/RouteEnum";

let UserContext = React.createContext();

const UserContextProvider = (props) => {
    const [user, setUser] = React.useState();
    let history = useHistory();

    const getUserFromUid = uid => {
        let uidSplit = uid.split(":");
        return {accountId: uidSplit[0], id: uidSplit[1]}
    }

    React.useEffect(() => {
        if (!props.uid) return;
        let user = getUserFromUid(props.uid);
        const unsub = firebaseApi.subscribeToUser(user.accountId, user.id, data => {
            let d = data.data();
            if (!d || !d.active) {
                history.push(RouteEnum.LOGOUT);
            }
            setUser(user => {return {...user, ...d}});
        });
        return () => unsub();
    }, [props.uid]);

    const loginUser = (uid, callback) => {
        let user = getUserFromUid(uid);
        setUser(user);
        firebaseApi.getUser(user.accountId, user.id, data => {
            let d = data.data();
            if (!d || !d.active) {
                history.push(RouteEnum.LOGOUT);
                return;
            }
            setUser(user => {return {...user, ...d}});
            if (callback) callback();
        });

        firebaseApi.subscribeToUser(user.accountId, user.id, data => {
            let d = data.data();
            if (!d || !d.active) {
                history.push(RouteEnum.LOGOUT);
                return;
            }
            setUser(user => {return {...user, ...d}});
        });
    }

    const updateUser = data => {
        setUser({...user, ...data});
        firebaseApi.updateUser(user.accountId, user.id, data, () => {});
    }

    const addFavorite = id => {
        let favorites = user.favorites || [];
        if (favorites.some(x => x === id)) return;
        favorites.push(id);
        updateUser({favorites: favorites});
    }

    const removeFavorite = id => {
        let favorites = user.favorites.filter(x => x !== id);
        updateUser({favorites: favorites});
    }

    return (
        <UserContext.Provider value={{ user, loginUser, updateUser, addFavorite, removeFavorite }}>
            {props.children}
        </UserContext.Provider>
    );
}

export { UserContext, UserContextProvider };