import React from "react";
import { UserContext } from "../../contexts/UserContext";
import firebaseApi from "../../apis/firebase/firebaseApi";
import Card from "../common/Card";
import { AccountContext } from "../../contexts/AccountContext";

const Meeting = props => {
    const {user} = React.useContext(UserContext);
    const {accountUsers} = React.useContext(AccountContext);
    const [participants, setParticipants] = React.useState([]);

    React.useEffect(() => {
        const unsub = firebaseApi.subscribeToMeetingParticipants(user.accountId, props.meeting.docId, participantDocs => {
            let participants = [];
            participantDocs.forEach(p => participants.push({...p.data(), docId: p.id}));

            let users = participants.map(p => {
                let user = accountUsers.filter(a => a.accountUserId === p.accountUserId);
                if (user.length) {
                    return { ...p, ...user[0] };
                }
                return p;
            });
            const noDups = users.reduce((acc, current) => {
                if (!current.accountUserId) {
                    const x = acc.find(item => item.name === current.name);
                    return !x ? acc.concat([current]) : acc;
                }
                const x = acc.find(item => item.accountUserId === current.accountUserId);
                return !x ? acc.concat([current]) : acc;
            }, []);
            noDups.sort((a, b) => {
                let aName = typeof(a.name) === "string" ? a.name : a.name.full;
                let bName = typeof(b.name) === "string" ? b.name : b.name.full;
                if (aName < bName) return -1;
                if (aName > bName) return 1;
                return 0;
            });

            setParticipants(noDups);
        })
        return () => unsub();
    }, [props.meeting.docId, accountUsers]);

    React.useEffect(() => {
        
    }, [props.meeting.docId]);

    let meeting = {...props.meeting};
    meeting.participants = participants;

    if (!participants || !participants.length) return null;

    if (!meeting.joinUrl) {
        if (accountUsers.some(x => x.accountUserId === meeting.hostId && x.zoomData && x.zoomData.personalMeetingUrl)) {
            meeting.joinUrl = accountUsers.filter(x => x.accountUserId === meeting.hostId && x.zoomData && x.zoomData.personalMeetingUrl)[0].zoomData.personalMeetingUrl;
        }
    }

    return (
        <Card meeting={meeting} />
    );
}


export default Meeting;