import React from "react";
import StatusEnum from "../../models/Enums/StatusEnum";
import RouteEnum from "../../models/Enums/RouteEnum";
import { UserContext } from "../../contexts/UserContext";
import dateHelpers from "../../helpers/dateHelpers";
import config from "../../apis/firebase/config";

const StatusDropdown = props => {
    let { user, updateUser } = React.useContext(UserContext);

    if (!props.show) return null;

    const onLogoutClick = () => {
        window.location = RouteEnum.LOGOUT;
    }

    const onClick = (status) => {
        //TODO: Save Status to User
        config.analytics.logEvent("status_changed", {status: status.type});
        updateUser({ status: status.type, timestampStatus: dateHelpers.getTimestamp() });
        if (props.onClick) {
            props.onClick();
        }
    }

    const getStatuses = () => {
        let e = StatusEnum;
        return Object.keys(e)
            .filter(x => !e[x].hideInDropdown)
            .map(x => {
                if (user.currentMeetings && user.currentMeetings.length && e[x].type === StatusEnum.ONLINE.type) {
                    x = StatusEnum.IN_MEETING.type
                }
                return {
                    type: e[x].type,
                    desc: e[x].desc,
                    icon: e[x].type.toLowerCase(),
                    dropdownClass: e[x].dropdownClass
                }
            });
    }

    const getStyle = status => {
        if (!user) return null;
        return user.status === status
            ? { ...styles.dropdownItem, ...styles.selectedDropdownItem }
            : styles.dropdownItem;
    }

    if (!user) return null;

    return (
        <div className="dropdown" style={styles.dropdownContainer}>
            {getStatuses().map(s => <div
                key={s.type}
                className={s.dropdownClass}
                onClick={() => onClick(s)}
                style={getStyle(s.type)}
            >
                <div className="dropdown-item-icon">
                    <img src={`/icons/no/${s.icon}.png`} alt="" />
                </div>
                <div className={`dropdown-item-name ${s.dropdownClass}`} style={styles.dropdownItemName}>{s.desc}</div>
                {
                    user.status === s.type &&
                    <i className="fas fa-check selected" />
                }
            </div>)}
            <div key="logout" style={styles.dropdownItem} onClick={onLogoutClick}>
                <div className="dropdown-item-icon">
                    <i className="fas fa-sign-out"></i>
                </div>
                <div className="dropdown-item-name" style={styles.dropdownItemName}>Logout</div>
            </div>
        </div>
    )
}

const styles = {
    dropdownContainer: {
        background: "#fff",
        boxShadow: "0px 8px 16px rgba(112, 112, 112, 0.18)",
        padding: "10px",
        position: "absolute",
        right: 0,
        top: 60,
        textAlign: "left",
        zIndex: 1000,
        fontSize: "18px",
        fontWeight: 800
    },
    dropdownItem: {
        height: "50px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",

    },
    selectedDropdownItem: {
        background: "#F7F7F7"
    },
    dropdownItemName: {
        flex: 1
    }
}

export default StatusDropdown;