import React from 'react';
import '@fortawesome/fontawesome-pro/css/all.css';
import { BrowserRouter } from 'react-router-dom';
import { UserContextProvider } from './contexts/UserContext';
import Router from './routes/Router';

const App = () => {
  return (
    <BrowserRouter>
      <UserContextProvider>
        <Router />
      </UserContextProvider>
      <div></div>
    </BrowserRouter>
  );
}

export default App;
