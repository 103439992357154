import React from "react";
import StatusEnum from "../../models/Enums/StatusEnum";
import { AccountContext } from "../../contexts/AccountContext";
import arrayHelpers from "../../helpers/arrayHelpers";
import Card from "../common/Card";

const Offline = () => {
    let { accountUsers } = React.useContext(AccountContext);

    const getUsers = () => {
        let users = accountUsers.filter(x => !x.status || x.status === StatusEnum.OFFLINE.type);
        return arrayHelpers.sort(users, "name.full");
    }

    let offline = {
        status: StatusEnum.OFFLINE.type,
        name: StatusEnum.OFFLINE.desc,
        isGrayedOut: StatusEnum.OFFLINE.isGrayedOut,
        participants: getUsers()
    }

    if (!offline.participants.length) return null;

    return (
        <>
            <h2><span>Offline Team Members</span></h2>
            <div className="meeting-container-full" style={styles.meetingsContainer}>
                <Card meeting={offline} />
            </div>
        </>
    );
}

const styles = {
    meetingsContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    }
}

export default Offline;