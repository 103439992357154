import React from "react";
import { UserContext } from "../../contexts/UserContext";

const Settings = () => {
    let { user, updateUser } = React.useContext(UserContext);

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const defaultSelectedDays = days.filter(x => x !== "Sunday" && x !== "Saturday")
        .map(x => { return { day: x, start: "8:00 AM", end: "5:00 PM" } });

    const [view, setView] = React.useState(user.defaultView || "COMPANY");
    const [team, setTeam] = React.useState(user.team || "");
    const [selectedDays, setSelectedDays] = React.useState(user.workHours || defaultSelectedDays);
    const [isTeamError, setIsTeamError] = React.useState(false);
    const [isDaysError, setIsDaysError] = React.useState(false);

    const [saveSuccess, setSaveSuccess] = React.useState(false);

    React.useEffect(() => {
        setView(user.defaultView || "COMPANY");
        setTeam(user.team || "");
        setSelectedDays(user.workHours || defaultSelectedDays);
    }, [user]);

    const onTeamChange = e => {
        setTeam(e.target.value);
    }

    const onViewChange = e => {
        setView(e.target.value);
    }

    const getDayStyle = day => {
        return selectedDays.some(x => x.day === day) ? styles.dayButtonSelected : styles.dayButton;
    }

    const onDayClick = day => {
        if (selectedDays.some(x => x.day === day)) {
            setSelectedDays(selectedDays.filter(x => x.day !== day));
        } else {
            setSelectedDays(selectedDays.concat({ day, start: "8:00 AM", end: "5:00 PM" }).sort((a, b) => {
                return days.indexOf(a.day) - days.indexOf(b.day)
            }));
        }
    }

    const onStartChange = (d, e) => {
        let newSelectedDays = [...selectedDays.filter(x => x.day !== d.day), { ...d, start: e.target.value }];
        newSelectedDays.sort((a, b) => {
            return days.indexOf(a.day) - days.indexOf(b.day)
        })
        setSelectedDays(newSelectedDays);
    }

    const onEndChange = (d, e) => {
        let newSelectedDays = [...selectedDays.filter(x => x.day !== d.day), { ...d, end: e.target.value }];
        newSelectedDays.sort((a, b) => {
            return days.indexOf(a.day) - days.indexOf(b.day)
        })
        setSelectedDays(newSelectedDays);
    }

    const onSaveClick = () => {
        let isValid = true;
        if (!team || !team.length) {
            setIsTeamError(true);
            isValid = false;
        }
        if (!selectedDays.length) {
            setIsDaysError(true);
            isValid = false;
        }
        if (!isValid) {
            return;
        }
        setIsTeamError(false);
        setIsDaysError(false);

        updateUser({ team: team, defaultView: view, workHours: selectedDays })

        setSaveSuccess(true);
        setTimeout(() => setSaveSuccess(false), 5000);
    }

    const getTimes = () => {
        let times = [];
        for (let i = 0; i < 24; i++) {
            let hour = i === 0 ? 12 : i > 12 ? i - 12 : i;
            times.push(`${hour}:00 ${i < 12 ? "AM" : "PM"}`);
            times.push(`${hour}:30 ${i < 12 ? "AM" : "PM"}`);
        }
        return times;
    }

    return (
        <div style={styles.settingsContainer}>
            <div style={styles.settingsHeader}>
                <i style={styles.settingsIcon} className="fas fa-cog" /> Settings
            </div>
            <div style={styles.formGroup}>
                <label style={styles.label}>Working Hours</label>
                <span>Please keep your working hours accurate to let people know when you’re working and available for meetings.</span>
                <div style={styles.daysWrapper}>
                    {days.map(d => <div key={d} onClick={() => onDayClick(d)} style={getDayStyle(d)}>
                        {d.charAt(0)}
                    </div>)}
                </div>
                <div>
                    {selectedDays.map(d => <div key={d.day} style={styles.workHoursContainer}>
                        <div style={styles.workHoursDay}>{d.day}</div>
                        <select value={d.start} onChange={e => onStartChange(d, e)}>
                            {getTimes().map(t => <option key={t} value={t}>{t}</option>)}
                        </select>
                        <div style={styles.workHoursTo}>to</div>
                        <select value={d.end} onChange={e => onEndChange(d, e)}>
                            {getTimes().map(t => <option key={t} value={t}>{t}</option>)}
                        </select>
                    </div>)}
                </div>
                {
                    isDaysError &&
                    <div style={styles.error}>Please select some working hours</div>
                }
            </div>
            <div style={styles.formGroup}>
                <label style={styles.label}>Default View</label>
                <select
                    value={view}
                    onChange={onViewChange}>
                    <option value="COMPANY">Company View</option>
                    <option value="TEAMS">Teams View</option>
                    <option value="MY_TEAM">My Team View</option>
                </select>
            </div>
            <div style={styles.formGroup}>
                <label style={styles.label}>My Team</label>
                <input
                    type="text"
                    className={isTeamError ? "error" : null}
                    value={team}
                    onChange={onTeamChange}
                />
                {
                    isTeamError &&
                    <div style={styles.error}>Please enter a team name</div>
                }
            </div>
            <div style={{display: "flex", alignItems: "center"}}> 
                <div style={{flex: 1}}>
                    {
                        saveSuccess &&
                        <div className="text-ok">
                            Settings saved!
                </div>
                    }</div>
                <div style={styles.saveContainer}>
                    <div onClick={onSaveClick} style={styles.saveButton}>Save</div>
                </div>
            </div>

        </div>
    );
}

const styles = {
    settingsContainer: {
        background: "#fff",
        boxShadow: "0px 16px 24px rgba(119, 119, 119, 0.14)",
        borderRadius: "20px",
        width: "100%",
        maxWidth: "1200px",
        margin: "25px auto",
        padding: "35px 40px",
        boxSizing: "border-box"
    },
    settingsHeader: {
        fontSize: "28px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        marginBottom: "20px"
    },
    settingsIcon: {
        color: "#1BB9DA",
        fontSize: "32px",
        marginRight: "17px"
    },
    formGroup: {
        marginBottom: "20px"
    },
    label: {
        width: "210px",
        color: "#333",
        fontWeight: "bold",
        display: "inline-block"
    },
    daysWrapper: {
        margin: "30px 0 30px 210px",
        display: "flex"
    },
    dayButton: {
        fontSize: "18px",
        fontWeight: "bold",
        background: "#eee",
        width: "50px",
        height: "50px",
        marginRight: "15px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        cursor: "pointer"
    },
    dayButtonSelected: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#fff",
        background: "#1BB9DA",
        width: "50px",
        height: "50px",
        marginRight: "15px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        cursor: "pointer"
    },
    workHoursContainer: {
        margin: "20px 0 0 210px",
        display: "flex",
        alignItems: "center"
    },
    workHoursDay: {
        fontSize: "16px",
        width: "145px",
    },
    workHoursTo: {
        fontSize: "16px",
        margin: "0 40px"
    },
    saveContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    saveButton: {
        fontWeight: 800,
        fontSize: "20px",
        background: "#1BB9DA",
        borderRadius: "20px",
        color: "#fff",
        width: "162px",
        height: "47px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "20px 0",
        cursor: "pointer"
    },
    error: {
        color: "#FF0038",
        marginLeft: "210px",
    }
}

export default Settings;