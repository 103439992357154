import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

const HeaderMenuItem = props => {
    let routeMatch = useRouteMatch({path: props.route, strict: true});
    let history = useHistory();

    const onClick = () => {
        if (props.route) {
            history.push(props.route);
        }
        if (props.onClick) {
            props.onClick();
        }
    }

    const getContainerStyle = () => {
        return routeMatch
            ? {...styles.iconContainer, color: "#1BB9DA"}
            : styles.iconContainer
    }

    return (
        <div onClick={onClick} style={getContainerStyle()}>
            <i style={styles.icon} className={props.iconClass} />
            {props.name}
        </div>
    );
}

const styles = {
    iconContainer: {
        display: "flex",
        flexDirection: "column",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "17px 12px",
        color: "#9F9F9F",
        cursor: "pointer"
    },
    icon: {
        fontSize: "20px",
        marginBottom: "5px"
    }
}

export default HeaderMenuItem;