import React from "react";

const AccountSuccess = props => {
    const onLoginClick = () => {
        window.location = "/login";
    }

    return (
        <div className="content-center">
            <h3>Authorization Complete</h3>
            <p className="text-center">Your account has been successfully authorized. Your organization can now log in and use the app.</p>
            <div className="text-center">
                <button onClick={onLoginClick} className="btn btn-ok">Login</button>
            </div>
        </div>
    )
}

export default AccountSuccess;