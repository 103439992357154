import React from "react";

const Login = () => {

    React.useEffect(()=> {
        const redirectUri = `${window.location.origin}/login/auth`;
        const clientId = "UwQTtapZT1WQzBk9VuUrRA";

        window.location = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${clientId}`;
    })
    
    return null;
}

export default Login;