import React from "react";
import StatusEnum from "../../models/Enums/StatusEnum";
import { UserContext } from "../../contexts/UserContext";
import meetingHelpers from "../../helpers/meetingHelpers";
import dateHelpers from "../../helpers/dateHelpers";
import { parsePhoneNumber } from "libphonenumber-js";

const Profile = props => {
    const textAreaRef = React.useRef();
    const profileContainerRef = React.useRef();
    let { user, addFavorite, removeFavorite } = React.useContext(UserContext);
    const [linkCopied, setLinkCopied] = React.useState(false);
    const [inviteCopied, setInviteCopied] = React.useState(false);

    const getStatus = () => {
        let matchedStatus = Object.keys(StatusEnum).filter(x => x === props.user.status);
        if (!matchedStatus || !matchedStatus.length) {
            return "Offline";
        }
        return StatusEnum[matchedStatus[0]].desc
    }

    const onJoinClick = () => {
        window.open(`${props.user.zoomData.personalMeetingUrl}`, "_blank");
    }

    const onLinkClick = () => {
        textAreaRef.current.value = `${props.user.zoomData.personalMeetingUrl}`;
        textAreaRef.current.select();
        document.execCommand("copy");
        textAreaRef.current.blur();

        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 5000);
    }

    const onInviteClick = () => {
        textAreaRef.current.value = meetingHelpers.inviteText(props.user.meetingId, props.user.zoomData.personalMeetingUrl);
        textAreaRef.current.select();
        document.execCommand("copy");
        textAreaRef.current.blur();

        setInviteCopied(true);
        setTimeout(() => setInviteCopied(false), 5000);
    }

    const getTimeZoneRemainder = () => {
        return (Math.abs(props.user.gmtOffset / 3600) - Math.floor(Math.abs(props.user.gmtOffset / 3600))) * 60
    }

    const getHour = time => {
        let hour = Math.floor(time.getUTCHours() + props.user.gmtOffset / 3600);
        let minutes = time.getUTCMinutes() + getTimeZoneRemainder();
        if (minutes > 60) hour = Math.floor(hour) + 1;
        if (hour < 0) hour = hour + 12;
        if (hour > 12) hour = hour - 12;
        return hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
    }

    const getAmPm = time => {
        let hour = time.getUTCHours() + props.user.gmtOffset / 3600;
        if ((hour < 24 && hour >= 12) || hour < 0) return "pm";
        return "am";
    }

    const getMinute = time => {
        let minutes = time.getUTCMinutes() + getTimeZoneRemainder();
        if (minutes > 60) minutes = minutes - 60;
        return minutes < 10 ? `0${minutes}` : minutes;
    }

    const getTime = () => {
        let currentTime = new Date();
        return `${getHour(currentTime)}:${getMinute(currentTime)} ${getAmPm(currentTime)}`
    }

    const getWorkingHours = () => {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let workHours = props.user.workHours.filter(x => x.day === days[new Date().getDay()]);
        if (!workHours.length) {
            //get next day
            let whDayIndexes = props.user.workHours.map(x => days.indexOf(x.day) < new Date().getDay() ? days.indexOf(x.day) - new Date().getDay() + 7 : days.indexOf(x.day) - new Date().getDay()).sort();
            workHours = props.user.workHours.filter(x => x.day === days[whDayIndexes[0]]);
            if (!workHours.length) {
                workHours = [props.user.workHours[0]]
            }
        }
        let workHoursDay = workHours[0];
        let datePrefix = `${new Date().getUTCMonth() + 1}/${new Date().getUTCDate()}/${new Date().getUTCFullYear()}`;

        let timeZoneOffset = new Date().getTimezoneOffset() * -60;
        let start = new Date(Date.parse(`${datePrefix} ${workHoursDay.start}`) - (props.user.gmtOffset * 1000) + (timeZoneOffset * 1000));
        let end = new Date(Date.parse(`${datePrefix} ${workHoursDay.end}`) - (props.user.gmtOffset * 1000) + (timeZoneOffset * 1000));

        return `${dateHelpers.getTimeString(start, true)} - ${dateHelpers.getTimeString(end, true)}`;
    }

    React.useLayoutEffect(() => {
        let rect = profileContainerRef.current.getBoundingClientRect();
        if (rect.width >= window.innerWidth) {
            profileContainerRef.current.style.left = `-${rect.x - 40}px`;
            profileContainerRef.current.style.width = `${window.innerWidth - 20}px`;
        } else {
            if (rect.right >= window.innerWidth) {
                profileContainerRef.current.style.left = `${window.innerWidth - rect.right - 20}px`;
            }
        }
        if (rect.bottom >= window.innerHeight) {
            profileContainerRef.current.style.top = `${window.innerHeight - rect.bottom - 20}px`;
        }

    })

    const getIcon = () => {
        if (!props.user.accountUserId) return null;
        let matchedStatus = Object.keys(StatusEnum).filter(x => x === props.user.status);
        if (!matchedStatus || !matchedStatus.length) {
            return `/icons/${StatusEnum.OFFLINE.type.toLowerCase()}.png`
            //return null;
        } else if (StatusEnum[matchedStatus[0]].hideIcon) {
            return null;
        }
        if (props.hideInMeetingIcon && matchedStatus[0] === StatusEnum.IN_MEETING.type) return null;
        return `/icons/${matchedStatus[0].toLowerCase()}.png`;
    }

    const getStatusClassName = () => {
        let matchedStatus = Object.keys(StatusEnum).filter(x => x === props.user.status);
        if (!matchedStatus || !matchedStatus.length) {
            return null;
        }
        return StatusEnum[matchedStatus[0]].dropdownClass;
    }

    const isFavorite = () => {
        if (!user.favorites) return false;
        return user.favorites.some(x => x === props.user.accountUserId);
    }

    const onFavoriteClick = () => {
        if (isFavorite()) {
            removeFavorite(props.user.accountUserId);
        } else {
            addFavorite(props.user.accountUserId);
        }
    }

    const hasTimeZoneOffset = () => {
        return !!props.user.gmtOffset;
    }

    const hasWorkHours = () => {
        return !!props.user.gmtOffset && props.user.workHours && !!props.user.workHours.length;
    }

    const hasPhoneNumber = () => {
        return (props.user.zoomData && props.user.zoomData.phoneNumber);
    }

    const getPhoneNumber = () => {
        if (!hasPhoneNumber()) return "";
        let phoneNumber = parsePhoneNumber(props.user.zoomData.phoneNumber);
        return phoneNumber.formatInternational();
    }

    return (
        <div className="profile-container" style={styles.profileContainer} ref={profileContainerRef}>
            <div style={styles.profileHeader}>
                <div style={styles.profilePic}>
                    {props.user.picUrl
                        ? <img src={props.user.picUrl} style={styles.profilePicImage} />
                        : <i className="fas fa-user-circle" />
                    }
                    {
                        <img style={styles.host} src={getIcon()} />
                    }
                </div>
                <div style={styles.profileHeaderMain}>
                    <div style={styles.profileHeaderFavorite} className="favorite">
                        {
                            isFavorite()
                                ? <img className="favorite-btn" onClick={onFavoriteClick} src="/favorite.png" />
                                : <img className="favorite-btn" onClick={onFavoriteClick} src="/favorite-off.png" />
                        }
                    </div>
                    <div style={styles.profileHeaderNameContainer}>
                        <div style={styles.profileName}>
                            {typeof (props.user.name) === "string" ? props.user.name : props.user.name.full}
                        </div>
                        <div className={getStatusClassName()} style={styles.profileStatus}>{getStatus()}</div>
                    </div>
                </div>
                {/* <div style={styles.close}><i className="fas fa-times" /></div> */}
            </div>
            <div></div>
            <div style={styles.footer}>
                {props.user.zoomData && props.user.zoomData.jobTitle && <div style={styles.footerLine}><label style={styles.footerLabel}>Job Title</label> <div className="text-overflow">{props.user.zoomData.jobTitle}</div></div>}
                {props.user.team && <div style={styles.footerLine}><label style={styles.footerLabel}>Department</label> <div className="text-overflow">{props.user.team}</div></div>}
                {hasWorkHours() && <div style={styles.footerLine}><label style={styles.footerLabel}>Working Hours</label> <div>{getWorkingHours()}</div></div>}
                {((props.user.zoomData && props.user.zoomData.location) || props.user.location) && <div style={styles.footerLine}><label style={styles.footerLabel}>Location</label> <div className="text-overflow">{props.user.zoomData ? props.user.zoomData.location : props.user.location}</div></div>}
                {hasTimeZoneOffset() && <div style={styles.footerLine}><label style={styles.footerLabel}>Local Time</label> <div>{getTime()}</div></div>}
                {hasPhoneNumber() && <div style={styles.footerLine}><label style={styles.footerLabel}>Phone</label> <div className="text-overflow">{getPhoneNumber()}</div></div>}
                {props.user.email && <div style={styles.footerLine}><label style={styles.footerLabel}>Email</label> <div className="text-overflow">{props.user.email}</div></div>}
            </div>
            {
                (props.user.zoomData && props.user.zoomData.personalMeetingUrl) &&
                <div className="profile-buttons" style={styles.meetingFooter}>
                    <div onClick={onLinkClick} style={styles.meetingFooterButton}>
                        <span style={linkCopied ? { visibility: "hidden" } : {}}>Link</span>
                        {linkCopied && <div className="tooltip active" title="Copied!"><i className="fas fa-check icon-copied" /></div>}
                    </div>
                    <div onClick={onInviteClick} style={styles.meetingFooterButton}>
                        <span style={inviteCopied ? { visibility: "hidden" } : {}}>Invite</span>
                        {inviteCopied && <div className="tooltip active" title="Copied!"><i className="fas fa-check icon-copied" /></div>}
                    </div>
                    <div onClick={onJoinClick} style={styles.meetingFooterButtonPrimary}>Join Room</div>
                    <div style={styles.textAreaContainer}>
                        <textarea ref={textAreaRef}></textarea>
                    </div>
                </div>
            }

        </div>
    )
}

const styles = {
    profileContainer: {
        background: "#FFFFFF",
        boxShadow: "0px 16px 24px rgba(119, 119, 119, 0.14)",
        borderRadius: "20px",
        maxWidth: "435px",
        width: "435px",
        padding: "20px",
        position: "absolute",
        top: "40px",
        left: "30px",
        zIndex: 888
    },
    profileHeader: {
        display: "flex",
        flexDirection: "row"
    },
    profilePic: {
        fontSize: "70px",
        marginRight: "5px",
        position: "relative"
    },
    profilePicImage: {
        width: "70px",
        marginBottom: "-15px",
        borderRadius: "50%"
    },
    host: {
        position: "absolute",
        bottom: "0px",
        right: "-10px"
    },
    profileHeaderMain: {
        display: "flex",
        flex: 1,
        textAlign: "left",
        alignSelf: "center"
    },
    profileHeaderFavorite: {
        marginRight: "12px"
    },
    profileName: {
        color: "#333",
        fontSize: "22px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "flex-end"
    },
    profileStatus: {
        color: "#666",
        fontWeight: "800",
        fontSize: "18px"
    },
    footerLine: {
        display: "flex",
        fontSize: "18px",
        color: "#333",
        alignItems: "center",
        marginTop: "5px",
        textAlign: "left"
    },
    footerLabel: {
        textTransform: "uppercase",
        color: "#9F9F9F",
        fontSize: "14px",
        fontWeight: "bold",
        width: "135px",
        textAlign: "left"
    },
    close: {
        cursor: "pointer",
        color: "#D6D6D6"
    },
    footer: {
        marginTop: "10px"
    },
    meetingFooter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        margin: "20px 0 10px"
    },
    meetingFooterButton: {
        color: "#666",
        fontSize: "20px",
        border: "1px solid rgba(102, 102, 102, 0.35)",
        borderRadius: "20px",
        padding: "11px 24px",
        margin: "0 5px",
        cursor: "pointer",
        fontWeight: "800",
        fontFamily: "Nunito",
        alignItems: "center"
    },
    meetingFooterButtonPrimary: {
        color: "#fff",
        fontSize: "20px",
        background: "#FF0038",
        borderRadius: "20px",
        padding: "11px 30px",
        margin: "0 5px",
        cursor: "pointer",
        fontWeight: "800"
    },
    textAreaContainer: {
        position: "absolute",
        left: "-9999px"
    }
}

export default Profile;