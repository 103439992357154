import React from "react";
import StatusEnum from "../../models/Enums/StatusEnum";
import { UserContext } from "../../contexts/UserContext";
import firebaseApi from "../../apis/firebase/firebaseApi";
import meetingHelpers from "../../helpers/meetingHelpers";

const InMeeting = props => {
    const textAreaRef = React.useRef();
    let { user, addFavorite, removeFavorite } = React.useContext(UserContext);
    const [linkCopied, setLinkCopied] = React.useState(false);
    const [inviteCopied, setInviteCopied] = React.useState(false);
    const [meeting, setMeeting] = React.useState();

    React.useEffect(() => {
        firebaseApi.getMeeting(props.user.accountId, props.user.currentMeetings[0], data => {
            setMeeting({ ...data.data(), id: data.id });
        });
    })

    const getStatus = () => {
        let matchedStatus = Object.keys(StatusEnum).filter(x => x === props.user.status);
        if (!matchedStatus || !matchedStatus.length) {
            return "Offline";
        }
        return StatusEnum[matchedStatus[0]].desc
    }

    const onJoinClick = () => {
        window.open(meeting.joinUrl, "_blank");
    }

    const onLinkClick = () => {
        textAreaRef.current.value = meeting.joinUrl;
        textAreaRef.current.select();
        document.execCommand("copy");

        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 5000);
    }

    const onInviteClick = () => {
        textAreaRef.current.value = meetingHelpers.inviteText(props.user.meetingId, meeting.joinUrl);
        textAreaRef.current.select();
        document.execCommand("copy");
        textAreaRef.current.blur();

        setInviteCopied(true);
        setTimeout(() => setInviteCopied(false), 5000);
    }

    const getTimeZoneRemainder = () => {
        return (Math.abs(props.user.gmtOffset / 3600) - Math.floor(Math.abs(props.user.gmtOffset / 3600))) * 60
    }

    const getHour = time => {
        let hour = Math.floor(time.getUTCHours() + props.user.gmtOffset / 3600);
        let minutes = time.getMinutes() + getTimeZoneRemainder();
        if (minutes > 60) hour = Math.floor(hour) + 1;
        if (hour < 0) hour = hour + 12;
        if (hour > 12) hour = hour - 12;
        return hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
    }

    const getAmPm = time => {
        let hour = time.getUTCHours() + props.user.gmtOffset / 3600;
        if ((hour < 24 && hour >= 12) || hour < 0) return "pm";
        return "am";
    }

    const getMinute = time => {
        let minutes = time.getMinutes() + getTimeZoneRemainder();
        if (minutes > 60) minutes = minutes - 60;
        return minutes < 10 ? `0${minutes}` : minutes;
    }

    const getTime = () => {
        let currentTime = new Date();
        return `${getHour(currentTime)}:${getMinute(currentTime)} ${getAmPm(currentTime)}`
    }

    const getIcon = () => {
        if (!props.user.accountUserId) return null;
        let matchedStatus = Object.keys(StatusEnum).filter(x => x === props.user.status);
        if (!matchedStatus || !matchedStatus.length) {
            return `/icons/${StatusEnum.OFFLINE.type.toLowerCase()}.png`
            //return null;
        } else if (StatusEnum[matchedStatus[0]].hideIcon) {
            return null;
        }
        if (props.hideInMeetingIcon && matchedStatus[0] === StatusEnum.IN_MEETING.type) return null;
        return `/icons/${matchedStatus[0].toLowerCase()}.png`;
    }

    const getStatusClassName = () => {
        let matchedStatus = Object.keys(StatusEnum).filter(x => x === props.user.status);
        if (!matchedStatus || !matchedStatus.length) {
            return null;
        }
        return StatusEnum[matchedStatus[0]].dropdownClass;
    }

    const isFavorite = () => {
        if (!user.favorites) return false;
        return user.favorites.some(x => x === props.user.accountUserId);
    }

    const onFavoriteClick = () => {
        if (isFavorite()) {
            removeFavorite(props.user.accountUserId);
        } else {
            addFavorite(props.user.accountUserId);
        }
    }

    return (
        <div className="profile-container" style={styles.profileContainer}>
            <div style={styles.profileHeader}>
                <div style={styles.profilePic}>
                    {props.user.picUrl
                        ? <img src={props.user.picUrl} style={styles.profilePicImage} />
                        : <i className="fas fa-user-circle" />
                    }
                    {
                        <img style={styles.host} src={getIcon()} />
                    }
                </div>
                <div style={styles.profileHeaderMain}>
                    <div style={styles.profileHeaderFavorite} className="favorite">
                        {
                            isFavorite()
                                ? <img className="favorite-btn" onClick={onFavoriteClick} src="/favorite.png" />
                                : <img className="favorite-btn" onClick={onFavoriteClick} src="/favorite-off.png" />
                        }
                    </div>
                    <div style={styles.profileHeaderNameContainer}>
                        <div style={styles.profileName}>
                            {typeof (props.user.name) === "string" ? props.user.name : props.user.name.full}
                        </div>
                        <div className={getStatusClassName()} style={styles.profileStatus}>{getStatus()}</div>
                    </div>
                </div>
            </div>
            {
                meeting &&
                <div className="meeting-container">
                    <div className="meeting-name">{meeting.name}</div>
                    {meeting.description && <div className="meeting-desc">{meeting.description}</div>}
                    {/* <div className="meeting-users">
                        {getMeetingParticipants().map(p => <div className={props.isHost ? "host" : null} className="profile-pic-container">
                            {props.user.picUrl
                                ? <img className="profile profile-pic" src={p.picUrl} />
                                : <i className="fas fa-user-circle profile profile-icon" />
                            }
                        </div>)}
                    </div> */}
                    <div className="meeting-container-footer">
                        <div onClick={onLinkClick} style={styles.meetingFooterButton}>
                            <span style={linkCopied ? { visibility: "hidden" } : {}}>Link</span>
                            {linkCopied && <div className="tooltip active" title="Copied!"><i className="fas fa-check icon-copied" /></div>}
                        </div>
                        <div onClick={onInviteClick} style={styles.meetingFooterButton}>
                            <span style={inviteCopied ? { visibility: "hidden" } : {}}>Invite</span>
                            {inviteCopied && <div className="tooltip active" title="Copied!"><i className="fas fa-check icon-copied" /></div>}
                        </div>
                        <div onClick={onJoinClick} style={styles.meetingFooterButtonPrimary}>Join</div>
                        <div style={styles.textAreaContainer}>
                            <textarea ref={textAreaRef}></textarea>
                        </div>
                    </div>
                </div>
            }

            <div style={styles.footer}>
                {props.user.team && <div style={styles.footerLine}><label style={styles.footerLabel}>Department</label> <div>{props.user.team}</div></div>}
                <div style={styles.footerLine}><label style={styles.footerLabel}>Location</label> <div>{props.user.zoomData ? props.user.zoomData.location : props.user.location}</div></div>
                {props.user.gmtOffset && <div style={styles.footerLine}><label style={styles.footerLabel}>Local Time</label> <div>{getTime()}</div></div>}
            </div>

        </div >
    )
}

const styles = {
    profileContainer: {
        background: "#FFFFFF",
        boxShadow: "0px 16px 24px rgba(119, 119, 119, 0.14)",
        borderRadius: "20px",
        maxWidth: "435px",
        width: "445px",
        padding: "20px",
        zIndex: 1,
        margin: "0 10px 20px"
    },
    profileHeader: {
        display: "flex",
        flexDirection: "row"
    },
    profilePic: {
        fontSize: "70px",
        marginRight: "5px",
        position: "relative"
    },
    profilePicImage: {
        width: "70px",
        marginBottom: "-15px",
        borderRadius: "50%"
    },
    host: {
        position: "absolute",
        bottom: "0px",
        right: "-10px"
    },
    profileHeaderMain: {
        display: "flex",
        flex: 1,
        textAlign: "left",
        alignSelf: "center"
    },
    profileHeaderFavorite: {
        marginRight: "12px"
    },
    profileName: {
        color: "#333",
        fontSize: "22px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "flex-end"
    },
    profileStatus: {
        color: "#666",
        fontWeight: "800",
        fontSize: "18px"
    },
    footerLine: {
        display: "flex",
        fontSize: "18px",
        color: "#333",
        alignItems: "center",
        marginTop: "5px"
    },
    footerLabel: {
        textTransform: "uppercase",
        color: "#9F9F9F",
        fontSize: "14px",
        fontWeight: "bold",
        width: "120px",
        textAlign: "left"
    },
    close: {
        cursor: "pointer",
        color: "#D6D6D6"
    },
    footer: {
        marginTop: "10px"
    },
    meetingFooter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        margin: "20px 0 10px"
    },
    meetingFooterButton: {
        color: "#666",
        fontSize: "20px",
        border: "1px solid rgba(102, 102, 102, 0.35)",
        borderRadius: "20px",
        padding: "11px 30px",
        margin: "0 10px",
        cursor: "pointer",
        fontWeight: "800",
        fontFamily: "Nunito",
        alignItems: "center"
    },
    meetingFooterButtonPrimary: {
        color: "#fff",
        fontSize: "20px",
        background: "#FF0038",
        borderRadius: "20px",
        padding: "11px 30px",
        margin: "0 10px",
        cursor: "pointer",
        fontWeight: "800"
    },
    textAreaContainer: {
        position: "absolute",
        left: "-9999px"
    }
}

export default InMeeting;