import React from "react";
import UnauthenticatedRouter from "./UnauthenticatedRouter";
import AuthenticatedRouter from "./AuthenticatedRouter";
import { UserContext } from "../contexts/UserContext";
import firebaseApi from "../apis/firebase/firebaseApi";
import config from "../apis/firebase/config";
import { Route } from "react-router-dom";
import RouteEnum from "../models/Enums/RouteEnum";

const Router = () => {
    const [authUser, setAuthUser] = React.useState();
    let { loginUser } = React.useContext(UserContext);

    React.useEffect(() => {
        firebaseApi.onAuthChanged(user => {
            setAuthUser(user);
            if (user) {
                loginUser(user.uid);
            }
        })
    }, []);

    if (authUser === undefined) return null;

    return (
        <>
            <Route path="/" render={({ location }) => {
                let title = Object.keys(RouteEnum).filter(x => RouteEnum[x] === location.pathname.toLowerCase());
                title = title.length ? title[0] : null;
                config.analytics.setCurrentScreen(location.pathname + location.search);
                config.analytics.logEvent("page_view", {page_location: location.pathname, page_title: title});
                return null;
            }} />
            {
                authUser
                    ? <AuthenticatedRouter />
                    : <UnauthenticatedRouter />
            }
        </>
    );
}

export default Router;