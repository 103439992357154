import React from "react";
import { UserContext } from "../../contexts/UserContext";
import firebaseApi from "../../apis/firebase/firebaseApi";
import StatusEnum from "../../models/Enums/StatusEnum";
import dateHelpers from "../../helpers/dateHelpers";

const OnlineModal = props => {
    let {user} = React.useContext(UserContext);

    const onImOnlineClick = () => {
        let check = user.onlineOfflineCheck;
        check.triggerOnlineCheck = false;
        check.triggerOfflineCheck = false;
        check.timestampCheck = Math.floor(new Date() / 1000);
        firebaseApi.updateUser(user.accountId, user.id, {onlineOfflineCheck: check, status: StatusEnum.ONLINE.type, timestampStatus: dateHelpers.getTimestamp()}, () => {})
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    Online Check
                </div>
                <div className="modal-body">
                    <div>If you're online, click the button below to go online.</div>
                    {
                        user.status === StatusEnum.NO_SHOW.type &&
                        <div className="mt-20 text-danger">Your status has been changed to <b>NO SHOW</b>.</div>
                    }
                </div>
                <div className="modal-footer text-center">
                    <button onClick={onImOnlineClick} type="button" className="btn btn-ok">I'm Here</button>
                </div>
            </div>
        </div>
    )
}

export default OnlineModal;