import React from "react";
import Profile from "../profile/Profile";
import useHover from "../../hooks/useHover";
import StatusEnum from "../../models/Enums/StatusEnum";

const User = props => {
    let [userRef, isHovered] = useHover(300);

    const getIcon = () => {
        if (props.isFavorite) return "/icons/favorite.png";
        if (!props.user.accountUserId || props.user.isVisitor) return null;
        if (!props.showIcon) return null;
        let matchedStatus = Object.keys(StatusEnum).filter(x => x === props.user.status);
        if (!matchedStatus || !matchedStatus.length) {
            return `/icons/${StatusEnum.OFFLINE.type.toLowerCase()}.png`
            //return null;
        } else if (StatusEnum[matchedStatus[0]].hideIcon) {
            return null;
        }
        if (props.hideInMeetingIcon && matchedStatus[0] === StatusEnum.IN_MEETING.type) return null;
        return `/icons/${matchedStatus[0].toLowerCase()}.png`;
    }

    const getName = () => {
        let name = typeof(props.user.name) === "string" ? props.user.name : props.user.name.full;
        return name ? name.replace(".", " ") : "";
    }

    const onImageError = e => {
        try{
            if (props.user.picUrl.indexOf("zoom.us") < 0 || e.target.src.indexOf("us06images.") > 0) return;
            let subdomain = new URL(props.user.picUrl).host.split(".")[0];
            e.target.src = props.user.picUrl.replace(subdomain + ".", "us06images.")
        }
        catch {}
    }

    return (
        <div className={`user-container`} key={props.user.id} style={styles.userContainer} ref={userRef}>
            <div className={props.isHost ? "host" : null} style={styles.profilePicContainer}>
                {props.user.picUrl
                    ? <img className="profile" src={props.user.picUrl} style={styles.profilePic} onError={onImageError} />
                    : <i style={styles.profileIcon} className="fas fa-user-circle profile" />
                }
                {
                    <img style={styles.host} src={getIcon()} />
                }
            </div>
            {
                !props.isFavorite &&
                <div className="text-overflow-2-line">{getName()}</div>
            }
            {(isHovered) && props.user.accountUserId && !props.user.isVisitor && <Profile showLinks={props.showLinks} isHover={true} user={props.user} />}
        </div>
    );
}

const styles = {
    userContainer: {
        width: "80px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        margin: "10px 10px",
        position: "relative",
        cursor: "pointer"
    },
    profilePicContainer: {
        textAlign: "center",
        color: "#666",
        marginBottom: "5px",
        position: "relative",
        height: "80px"
    },
    profileIcon: {
        fontSize: "70px",
    },
    host: {
        position: "absolute",
        bottom: "-10px",
        right: "-10px"
    },
    profilePic: {
        width: "80px",
        borderRadius: "50%"
    },
}

export default User;