import React from "react";
import Meetings from "../meetings/Meetings";
import Favorites from "../common/Favorites";
import Statuses from "../meetings/Statuses";
import Online from "../meetings/Online";
import Offline from "../meetings/Offline";

const Company = () => {
    return (
        <>
            <Favorites />
            <Meetings />
            <Statuses />
            <Online />
            <Offline />
        </>
    )
}

export default Company;