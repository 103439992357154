import config from "./config";

const firebaseApi = {
    onAuthChanged: (callback) => {
        config.auth.onAuthStateChanged(user => {
            if (user && user.uid.indexOf(":") === -1) {
                config.auth.signOut();
            }
            if (user) {
                config.analytics.setUserId(user.uid);
                let userProps = user.uid.split(":");
                config.analytics.setUserProperties({accountId: userProps[0], userId: userProps[1]});
            }
            callback(user);
        })
    },

    login: (token, callback) => {
        config.auth.signInWithCustomToken(token)
            .then(data => {
                if (callback) callback();
            }).catch(error => {
                console.log(error);
            });
    },

    logout: callback => {
        config.auth.signOut()
            .then(callback);
    },

    subscribeToMeetings: (accountId, callback) => {
        return config.firebase.firestore()
            .collection("accounts")
            .doc(accountId)
            .collection("accountMeetings")
            .where("active", "==", true)
            .onSnapshot(callback);
    },

    subscribeToMeetingParticipants: (accountId, meetingId, callback) => {
        return config.firebase.firestore()
            .collection("accounts")
            .doc(accountId)
            .collection("accountMeetings")
            .doc(meetingId)
            .collection("accountMeetingParticipants")
            .onSnapshot(callback);
    },

    getMeeting: (accountId, meetingId, callback) => {
        config.firebase.firestore()
            .collection("accounts")
            .doc(accountId)
            .collection("accountMeetings")
            .doc(meetingId)
            .get()
            .then(callback);
    },

    updateMeeting: (accountId, meetingId, data, callback) => {
        meetingId = meetingId.toString();
        return config.firebase.firestore()
            .collection("accounts")
            .doc(accountId)
            .collection("accountMeetings")
            .doc(meetingId)
            .set(data, { merge: true })
            .then(callback);
    },

    updateUser: (accountId, userId, data, callback) => {
        config.firebase.firestore()
            .collection("accounts")
            .doc(accountId)
            .collection("accountUsers")
            .doc(userId)
            .set(data, { merge: true })
            .then(callback);
    },

    subscribeToUsers: (accountId, callback) => {
        return config.firebase.firestore()
            .collection("accounts")
            .doc(accountId)
            .collection("accountUsers")
            .onSnapshot(callback);
    },

    getUsers: (accountId, callback) => {
        return config.firebase.firestore()
            .collection("accounts")
            .doc(accountId)
            .collection("accountUsers")
            .get()
            .then(callback);
    },

    subscribeToUsersByTeam: (accountId, team, callback) => {
        return config.firebase.firestore()
            .collection("accounts")
            .doc(accountId)
            .collection("accountUsers")
            .where("team", "==", team)
            .onSnapshot(callback);
    },

    subscribeToUser: (accountId, userId, callback) => {
        return config.firebase.firestore()
            .collection("accounts")
            .doc(accountId)
            .collection("accountUsers")
            .doc(userId)
            .onSnapshot(callback);
    },

    getUser: (accountId, userId, callback) => {
        config.firebase.firestore()
            .collection("accounts")
            .doc(accountId)
            .collection("accountUsers")
            .doc(userId)
            .get()
            .then(callback);
    },

    addFeedback: (feedback) => {
        config.firebase.firestore()
            .collection("feedback")
            .add(feedback)
            .then(() => {});
    }
}

export default firebaseApi;