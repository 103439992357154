import React from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";

import RouteEnum from "../models/Enums/RouteEnum";
import { UserContext } from "../contexts/UserContext";
import Settings from "../components/settings/Settings";
import Teams from "../components/teams/Teams";
import MyTeam from "../components/teams/MyTeam";
import Company from "../components/company/Company";
import Logout from "../components/login/Logout";
import Support from "../components/support/Support";
import Terms from "../components/support/Terms";
import Privacy from "../components/support/Privacy";
import AuthenticatedHome from "../components/home/AuthenticatedHome";
import Search from "../components/search/Search";
import Header from "../components/common/Header";
import { AccountContextProvider } from "../contexts/AccountContext";
import OnlineOffline from "../components/modals/OnlineOffline";
import AppDoc from "../components/support/AppDoc";
import LoginDoc from "../components/support/LoginDoc";
import FAQ from "../components/support/FAQ";


const AuthenticatedRouter = () => {
    const [searchText, setSearchText] = React.useState("");
    const [showSearch, setShowSearch] = React.useState(false);
    let { user } = React.useContext(UserContext);
    let history = useHistory();

    const onSearchChange = searchText => {
        setSearchText(searchText)
    }

    const onShowSearch = () => {
        setShowSearch(!showSearch);
    }

    const onCloseSearch = () => {
        setShowSearch(false);
    }

    if (!user) return null;

    if (user.active === false) {
        window.location = RouteEnum.LOGOUT;
    }

    return (
        <>
            <Header onSearchChange={onSearchChange} onShowSearch={onShowSearch} showSearch={showSearch} />
            {
                searchText || showSearch
                    ? <Search text={searchText} onTextChange={onSearchChange} closeSearch={onCloseSearch} />
                    : <AccountContextProvider accountId={user.accountId}>
                        <Switch>
                            <Route exact={true} path={RouteEnum.APP} component={AuthenticatedHome} />
                            <Route exact={true} path={RouteEnum.COMPANY} component={Company} />
                            <Route exact={true} path={RouteEnum.SETTINGS} component={Settings} />
                            <Route exact={true} path={RouteEnum.TEAMS} component={Teams} />
                            <Route exact={true} path={RouteEnum.MY_TEAM} component={MyTeam} />
                            <Route exact={true} path={RouteEnum.FAQ} component={FAQ} />
                            <Route exact={true} path={RouteEnum.LOGOUT} component={Logout} />
                            <Route exact={true} path={RouteEnum.SUPPORT} component={Support} />
                            <Route exact={true} path={RouteEnum.TERMS} component={Terms} />
                            <Route exact={true} path={RouteEnum.PRIVACY} component={Privacy} />
                            <Route exact={true} path={RouteEnum.APP_DOC} component={AppDoc} />
                            <Route exact={true} path={RouteEnum.LOGIN_DOC} component={LoginDoc} />
                            <Route exact={true}>
                                <Redirect to={RouteEnum.APP} />
                            </Route>
                        </Switch>
                    </AccountContextProvider>
            }
            <OnlineOffline />
        </>
    )
};

export default AuthenticatedRouter;