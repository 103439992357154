import React from 'react';
import firebaseApi from '../apis/firebase/firebaseApi';

let AccountContext = React.createContext();

const AccountContextProvider = (props) => {
    const [accountUsers, setAccountUsers] = React.useState([]);

    React.useEffect(() => {
        const unsub = firebaseApi.subscribeToUsers(props.accountId, data => {
            let items = [];
            data.forEach(x => items.push({...x.data(), id: x.id}));
            items = items.filter(x => x.active !== false);
            setAccountUsers(items);
        });
        return () => unsub();
    }, [props.accountId]);

    return (
        <AccountContext.Provider value={{ accountUsers }}>
            {props.children}
        </AccountContext.Provider>
    );
}

export { AccountContext, AccountContextProvider };