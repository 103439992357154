import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import RouteEnum from "../../models/Enums/RouteEnum";

const UnauthenticatedHeader = () => {
    let history = useHistory();
    let location = useLocation();

    const onLoginClick = () => {
        window.location = "https://zoom.us/oauth/authorize?response_type=code&client_id=toFDmn4T52i5ComM7D1Ag&redirect_uri=https://insideyourbusiness.com/account/auth";
    }

    const onLogoClick = () => {
        history.push(RouteEnum.HOME);
    }

    return (
        <div className="home-header-container">
            <div className="header-container">
                <div><img onClick={onLogoClick} className="logo" src="/IYB-white.png" /></div>
                <div className="right-menu-mobile">
                    <div>
                        <span>Returning Members | </span><a href={RouteEnum.LOGIN} className="btn-link"><i className="fas fa-sign-in" /> Login</a>
                    </div>
                    <div>
                        <button onClick={onLoginClick} className="btn-white desktop-only">Get Started</button>
                    </div>
                </div>
            </div>
            {
                location.pathname === "/" &&
                <div className="headline">
                    <h1>Your Remote Office - Visualized</h1>
                    <p>Integrate your company Zoom account</p>
                </div>
            }

        </div>
    );

}

export default UnauthenticatedHeader;