import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';

// PROD
const firebaseConfig = {
    apiKey: "AIzaSyBMhIZZh3UMn1UjaWVmsU3RnaiEDEMYDvo",
    authDomain: "powerfront-zoom.firebaseapp.com",
    databaseURL: "https://powerfront-zoom.firebaseio.com",
    projectId: "powerfront-zoom",
    storageBucket: "powerfront-zoom.appspot.com",
    messagingSenderId: "1090242889059",
    appId: "1:1090242889059:web:dfbce3d0c4a901a7903f56",
    measurementId: "G-JW0E34XXKB"
};

/*
// DEV
const firebaseConfig = {
    apiKey: "AIzaSyAsMk4t7U5iTXmJOMu4J_K8a5mmso3f0Ec",
    authDomain: "powerfront-zoom-dev.firebaseapp.com",
    databaseURL: "https://powerfront-zoom-dev.firebaseio.com",
    projectId: "powerfront-zoom-dev",
    storageBucket: "powerfront-zoom-dev.appspot.com",
    messagingSenderId: "712341627767",
    appId: "1:712341627767:web:9d02851143cea633053772",
    measurementId: "G-67CPPQ8XY4"
  };
*/

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

let analytics = firebase.analytics();

let config = {
    firebase: firebase,
    auth: firebase.auth(),
    analytics: analytics
}

export default config;