import React from "react";
import User from "./User";
import { UserContext } from "../../contexts/UserContext";
import firebaseApi from "../../apis/firebase/firebaseApi";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import meetingHelpers from "../../helpers/meetingHelpers";

const Card = props => {
    let { user } = React.useContext(UserContext);
    const textAreaRef = React.useRef();
    const editMeetingDescRef = React.useRef();
    const [linkCopied, setLinkCopied] = React.useState(false);
    const [inviteCopied, setInviteCopied] = React.useState(false);
    const [editMeetingDesc, setEditMeetingDesc] = React.useState(false);
    const [meetingDesc, setMeetingDesc] = React.useState(props.meeting.description);
    const [isShown, setIsShown] = React.useState();

    React.useEffect(() => {
        if (editMeetingDesc) {
            editMeetingDescRef.current.focus();
        }
    }, [editMeetingDesc]);

    React.useEffect(() => {
        setIsShown(true);
        return () => setIsShown(false)
    }, []);

    const onLinkClick = () => {
        let meetingUrl = props.meeting.joinUrl || `https://zoom.us/j/${props.meeting.docId}`;
        textAreaRef.current.value = meetingUrl;
        textAreaRef.current.select();
        document.execCommand("copy");
        textAreaRef.current.blur();

        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 5000);
    }

    const onInviteClink = () => {
        let meetingUrl = props.meeting.joinUrl || `https://zoom.us/j/${props.meeting.docId}`;
        const copyText = meetingHelpers.inviteText(props.meeting.docId, meetingUrl)

        textAreaRef.current.value = copyText;
        textAreaRef.current.select();
        document.execCommand("copy");
        textAreaRef.current.blur();

        setInviteCopied(true);
        setTimeout(() => setInviteCopied(false), 5000);
    }

    const onJoinClick = () => {
        let meetingUrl = props.meeting.joinUrl || `https://zoom.us/j/${props.meeting.docId}`;
        window.open(meetingUrl, "_blank");
    }

    const getMeetingHeaderContainerStyle = () => {
        let backgroundImage = props.meeting.status
            ? `url(backgrounds/${props.meeting.status.toLowerCase()}.png)`
            : "url(backgrounds/meeting-header-background.png)";
        if (props.meeting.team) {
            backgroundImage = "url(backgrounds/teams.png)"
        }
        if (props.meeting.isPrivate) {
            backgroundImage = `linear-gradient(0deg, rgba(170, 3, 3, 0.52), rgba(170, 3, 3, 0.52)), linear-gradient(0deg, rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.22)), linear-gradient(117.07deg, #4D4949 28.74%, rgba(101, 100, 100, 0.589232) 44.05%, rgba(255, 255, 255, 0) 67.21%), ${backgroundImage}`;
        }
        return { ...styles.meetingHeaderContainer, background: backgroundImage };
    }

    const getIcon = () => {
        return props.meeting.status
            ? null // `/icons/${props.meeting.status.toLowerCase()}.png`
            : "/icons/in_meeting.png"
    }

    const getParticipants = () => {
        let array = props.meeting.participants.filter(x => x.accountUserId === props.meeting.hostId);
        let otherMembers = props.meeting.participants.filter(x => x.accountUserId !== props.meeting.hostId);
        //arrayHelpers.sort(otherMembers, "name");
        otherMembers.sort((a, b) => {
            let aName = typeof(a.name) === "string" ? a.name : a.name.full;
            let bName = typeof(b.name) === "string" ? b.name : b.name.full;
            if (aName < bName) return -1;
            if (aName > bName) return 1;
            return 0;
        })
        return array.concat(otherMembers);
    }

    const isHost = userId => {
        if (!props.meeting.hostId) return false;
        return props.meeting.hostId === userId;
    }

    const onEditMeetingDescription = () => {
        setEditMeetingDesc(true);
    }

    const onEditMeetingDescChange = e => {
        setMeetingDesc(e.target.value);
    }

    const onEditMeetingDescBlur = () => {
        firebaseApi.updateMeeting(user.accountId, user.meetingId, { description: meetingDesc }, () => {
            setEditMeetingDesc(false);
        });
    }

    const getContainerClass = () => {
        let showClass = isShown === undefined
            ? null
            : isShown ? "fade-in" : "fade-out";

        let isGrayedOutClass = props.meeting.isGrayedOut ? "gray-out" : null;
        return `meeting-container ${isGrayedOutClass} ${showClass}`
    }

    return (
        <div key={props.meeting.docId || props.meeting.status || props.meeting.team}
            style={styles.meetingContainer}
            className={getContainerClass()}>
            <div style={getMeetingHeaderContainerStyle()}>
                <div className="meeting-header" style={styles.meetingHeader}>
                    {props.meeting.team
                        ? <i className="fas fa-users-class" />
                        : <img src={getIcon()} />}
                    <div style={styles.meetingHeaderName}>{props.meeting.name}</div>
                    {props.meeting.isPrivate && <div className="private">PRIVATE</div>}
                </div>
            </div>
            {
                isHost(user.id)
                    ? <div onClick={onEditMeetingDescription} className="meeting-desc-container">
                        {
                            editMeetingDesc
                                ? <input
                                    ref={editMeetingDescRef}
                                    type="text"
                                    placeholder="Edit Meeting Description"
                                    onBlur={onEditMeetingDescBlur}
                                    onChange={onEditMeetingDescChange}
                                    value={meetingDesc}
                                />
                                : <>
                                    <div style={styles.meetingDescription}>{props.meeting.description || "Click to edit the meeting description"}</div>
                                    <i className="far fa-pencil" />
                                </>
                        }

                    </div>
                    : props.meeting.description ?
                        <div>
                            <div style={styles.meetingDescription}>{props.meeting.description}</div>
                        </div>
                        : null
            }
            <div className="user-list" style={styles.participantList}>
                <TransitionGroup>
                    {getParticipants().map((p, i) =>
                        <CSSTransition
                            key={p.accountUserId || p.docId}
                            timeout={1000}
                            classNames="item"
                        >
                            <User showLinks={props.showLinks} key={p.meetingParticipantId || p.accountUserId} user={p} isHost={isHost(p.accountUserId)} hideInMeetingIcon={!props.meeting.team} showIcon={!props.meeting.status} />
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </div>
            {
                !props.meeting.status && !props.meeting.team && !props.meeting.isPrivate &&
                <div style={styles.meetingFooter}>
                    <div onClick={onLinkClick} style={styles.meetingFooterButton}>
                        <span style={linkCopied ? { visibility: "hidden" } : {}}>Link</span>
                        {linkCopied && <div className="tooltip active" title="Copied!"><i className="fas fa-check icon-copied" /></div>}
                    </div>
                    <div onClick={onInviteClink} style={styles.meetingFooterButton}>
                        <span style={inviteCopied ? { visibility: "hidden" } : {}}>Invite</span>
                        {inviteCopied && <div className="tooltip active" title="Copied!"><i className="fas fa-check icon-copied" /></div>}
                    </div>
                    <div onClick={onJoinClick} style={styles.meetingFooterButtonPrimary}>Join</div>
                </div>
            }

            <div style={styles.textAreaContainer}>
                <textarea ref={textAreaRef}></textarea>
            </div>
        </div>
    );
}

const styles = {
    meetingContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "20px",
        background: "#fff",
        padding: "20px",
        borderRadius: "20px",
        boxShadow: "0px 16px 24px rgba(119, 119, 119, 0.14)",
        maxWidth: "488px",
        width: "488px"

    },
    meetingHeaderContainer: {
        display: "flex",
        flexDirection: "row",
        height: "120px",
        margin: "-20px -20px 0",
        color: "#fff",
        //backgroundImage: "url(backgrounds/meeting-header-background.png)",
        backgroundSize: "cover",
        borderRadius: "20px 20px 0 0"
    },
    meetingHeader: {
        margin: "45px 35px 0",
        display: "flex",
        alignItems: "center",
        fontSize: "24px",
        fontWeight: "bold",
    },
    meetingHeaderName: {
        marginLeft: "16px",
        flex: 1
    },
    meetingDescription: {
        background: "#f7f7f7",
        borderRadius: "15px",
        fontSize: "16px",
        padding: "6px 23px",
        lineHeight: "36px",
        marginTop: "20px"
    },
    participantList: {
        listStyle: "none",
        textAlign: "left",
        color: "#333",
        flex: "1 0 auto",
        padding: "20px 4px"
    },
    participantIcons: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    meetingFooter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        margin: "10px -5px"
    },
    meetingFooterButton: {
        color: "#666",
        fontSize: "20px",
        border: "1px solid rgba(102, 102, 102, 0.35)",
        borderRadius: "20px",
        padding: "11px 30px",
        margin: "0 5px",
        cursor: "pointer",
        fontWeight: "800",
        fontFamily: "Nunito",
        alignItems: "center",
        position: "relative",
        flexGrow: 1,
        flexBasis: 0,
        textAlign: "center"
    },
    meetingFooterButtonPrimary: {
        color: "#fff",
        fontSize: "20px",
        background: "#FF0038",
        borderRadius: "20px",
        padding: "11px 30px",
        margin: "0 5px",
        cursor: "pointer",
        fontWeight: "800",
        flexGrow: 1,
        flexBasis: 0,
        textAlign: "center"
    },
    textAreaContainer: {
        position: "absolute",
        left: "-9999px"
    }
}

export default Card;