import React from "react";
import { UserContext } from "../../contexts/UserContext";
import { AccountContext } from "../../contexts/AccountContext";
import User from "./User";
import arrayHelpers from "../../helpers/arrayHelpers";

const Favorites = props => {
    let { user } = React.useContext(UserContext);
    let { accountUsers } = React.useContext(AccountContext);

    if (!user || !user.favorites || !accountUsers || !accountUsers.length) return null;

    let favorites = user.favorites
        .filter(x => accountUsers.some(ua => ua.accountUserId === x))
        .map(x => {
            return accountUsers.filter(au => au.accountUserId === x)[0];
        })

    arrayHelpers.sort(favorites, "name.full");

    return (
        <div className="favorites-container">
            {favorites.map(u => <User key={u.accountUserId} user={u} isFavorite={true} showIcon={true} showLinks={true} />)}
        </div>
    )
}

export default Favorites;