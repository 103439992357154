import React from "react";
import { UserContext } from "../../contexts/UserContext";
import { useHistory } from "react-router-dom";
import RouteEnum from "../../models/Enums/RouteEnum";

const AuthenticatedHome = () => {
    let {user} = React.useContext(UserContext);
    let history = useHistory();
    if (!user.accountId) return null;
    history.push(user.defaultView ? RouteEnum[user.defaultView] : RouteEnum.COMPANY);

    return null;
}

export default AuthenticatedHome;