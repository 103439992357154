import React from "react";

const LoginDoc = () => {
    return (
        <div className="content-center">
            <h2>INSIDE Your Business Login Documentation</h2>
            <h3>Installation</h3>
            <p><b>Prerequisite:</b> To fully use this app, an admin for your Zoom account must first authorize the <a href="/account/authorize"><b>INSIDE Your Business</b></a> app.</p>
            <p className="mt-60">In order to install the <b>INSIDE Your Business Login</b> app, you must authorize the app.  Visit the <a href="/login">Login</a> page, login to Zoom, and click the authorize button (see screenshot below).  We only use this app to verify your login details.</p>
            <div className="text-center">
                <img style={{border: "2px solid #1BB9DA", borderRadius: "20px"}} src="/documentation/AuthorizeLoginApp.png" alt="" />
            </div>
            <h4>Deauthorizing the App</h4>
            <p>In order to logout of the app, <a href="/logout">click here</a> or hover over your profile image on the top left and choose "Logout".</p>
            <p>If you would like to uninstall the app from your Zoom Installed Apps, head over to the <a href="https://marketplace.zoom.us/user/installed">Zoom Marketplace</a> and click uninstall next to the <b>INSIDE Your Business Login</b> app.</p>

            <h3 className="mt-40">Usage</h3>
            <p>Once you've logged into the app, you will see your current meetings and teams.</p>

            <h3>Troubleshooting</h3>
            <h5>Unable to Login</h5>
            <p>If you are unable to login to the app, verify that the INSIDE Your Business app has been authorized by an account admin.</p>
            
            <h3>Support</h3>
            <p>For any questions, comments, concerns, please visit our <a href="/support">Support</a> page.</p>
            
            <h3>FAQ</h3>
            <p>Coming Soon!</p>
        </div>
    )
}

export default LoginDoc;