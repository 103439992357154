import React from "react";
import firebaseApi from "../../apis/firebase/firebaseApi";
import { UserContext } from "../../contexts/UserContext";

const StartMeetingModal = props => {
    let { user } = React.useContext(UserContext);
    const [meetingDescription, setMeetingDescription] = React.useState("");
    const [charLeftCount, setCharLeftCount] = React.useState(50);
    const [isPrivate, setIsPrivate] = React.useState(false);
    const meetingDescriptionRef = React.useRef();

    React.useEffect(() => {
        if (props.show && meetingDescriptionRef.current) {
            meetingDescriptionRef.current.focus();
        }
    }, [props.show])

    if (!props.show) return null;

    const onCloseClick = () => {
        props.onClose();
    }

    const onMeetingDescriptionChange = e => {
        setMeetingDescription(e.target.value);
        setCharLeftCount(50 - e.target.value.length);
    }

    const onMeetingDescriptionKeyPress = e => {
        if (e.key === "Enter") {
            onStartMeetingClick();
        }
    }

    const onCheckboxChange = e => {
        setIsPrivate(e.target.checked);
    }

    const onStartMeetingClick = () => {
        //if (!meetingDescription.length) return;
        let data = { description: meetingDescription, isPrivate: isPrivate }
        firebaseApi.updateMeeting(user.accountId, user.meetingId, data, () => { });
        props.onClose();
        window.open(user.zoomData.personalMeetingUrl, "_blank");
    }

    return (
        <div className="start-meeting-modal">
            <div className="modal-content">
                <div className="modal-header">
                    Starting Your Zoom Meeting...
                    <div onClick={onCloseClick} className="close"><i className="fas fa-times" /></div>
                </div>
                <div className="modal-body">
                    <input
                        ref={meetingDescriptionRef}
                        type="text"
                        placeholder="What is this meeting about?"
                        maxLength="50"
                        value={meetingDescription}
                        onChange={onMeetingDescriptionChange}
                        onKeyPress={onMeetingDescriptionKeyPress}
                    />
                    <div className="limit-text">Limit: 50 characters - Remaining: {charLeftCount}</div>
                    <div className="desc-text">
                        Please add a meeting description for the team to see what this meeting is about
                </div>
                </div>
                <div className="modal-footer">
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" checked={isPrivate} onChange={onCheckboxChange} />
                        Private Meeting
                    </label>
                    </div>
                    <button className="btn btn-ok" onClick={onStartMeetingClick}>Start Meeting</button>
                </div>
            </div>
        </div>
    )
}

export default StartMeetingModal;