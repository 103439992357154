import React from "react";
import StatusEnum from "../../models/Enums/StatusEnum";
import { UserContext } from "../../contexts/UserContext";
import firebaseApi from "../../apis/firebase/firebaseApi";
import arrayHelpers from "../../helpers/arrayHelpers";
import Card from "../common/Card";

const Online = () => {
    let { user } = React.useContext(UserContext);
    const [users, setUsers] = React.useState([]);

    React.useEffect(() => {
        firebaseApi.subscribeToUsers(user.accountId, data => {
            let items = [];
            data.forEach(x => {
                let item = x.data();
                // if (!item.status) {
                //     item.status = StatusEnum.OFFLINE.type;
                // }
                if (item.status === StatusEnum.ONLINE.type
                    && item.team
                    && (!item.currentMeetings || !item.currentMeetings.length)) {
                    items.push(item);
                }
            });
            setUsers(items);
        })
    }, []);

    const groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const groupTeams = () => {
        let groups = groupBy(users, "team");

        let list = Object.keys(groups).map(g => {
            return {
                team: g,
                name: g,
                participants: arrayHelpers.sort(groups[g], "name.full")
            }
        })

        return arrayHelpers.sort(list, "team");
    }

    if (!users.length) return null;

    let teams = groupTeams();

    return (
        <>
            {teams.length &&
                <h2><span>Online Team Members</span></h2>
            }
            <div style={styles.meetingsContainer}>
                {teams.map(t => <Card key={t.name} meeting={t} />)}
            </div>
        </>
    );
}

const styles = {
    meetingsContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    }
}

export default Online;