import React from "react";
import { UserContext } from "../../contexts/UserContext";
import OfflineModal from "./OfflineModal";
import OnlineModal from "./OnlineModal";

const OnlineOffline = props => {
    let {user} = React.useContext(UserContext);

    if (!user.onlineOfflineCheck) return null;

    if (user.onlineOfflineCheck.triggerOnlineCheck) {
        return <OnlineModal />;
    }

    if (user.onlineOfflineCheck.triggerOfflineCheck) {
        return  <OfflineModal />;
    }

    return null;
}

export default OnlineOffline;