import React from "react";
import firebaseApi from "../../apis/firebase/firebaseApi";

const Support = () => {
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [feedback, setFeedback] = React.useState("");
    const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false);

    const onNameChange = e => {
        setName(e.target.value);
    }

    const onEmailChange = e => {
        setEmail(e.target.value);
    }

    const onFeedbackChange = e => {
        setFeedback(e.target.value);
    }

    const submit = () => {
        firebaseApi.addFeedback({ name, email, feedback });
        setFeedbackSubmitted(true);
    }

    return (
        <div className="content-center">
            <h2>INSIDE Your Business Support</h2>
            <p>For any support related concerns, please email us at: <a href="mailto:support@insideyourbusiness.com">support@insideyourbusiness.com</a></p>

            <p>Our customer support team will answer your questions in one business day.  Our Customer support team's working hours are 8:00am to 5:00pm PST.</p>

            {
                feedbackSubmitted
                    ? <div className="text-ok">Your feedback has been submitted.</div>
                    : <>
                        <div className="form-group">
                            <label>Name:</label>
                            <input type="text" value={name} onChange={onNameChange} />
                        </div>

                        <div className="form-group">
                            <label>Email:</label>
                            <input type="text" value={email} onChange={onEmailChange} />
                        </div>

                        <div className="form-group">
                            <label>Feedback:</label>
                            <textarea rows="5" value={feedback} onChange={onFeedbackChange}></textarea>
                        </div>

                        <div className="form-group">
                            <button onClick={submit} type="button" className="btn">Submit</button>
                        </div>
                    </>
            }



            {/* <p className="text-danger"><a href="https://docs.google.com/forms/d/e/1FAIpQLScekJjsvmjyvesO13clyoPWjJA5r3X5-B1w3ya1Jw7525exDQ/viewform?usp%3Dsf_link&sa=D&source=hangouts&ust=1587040126374000&usg=AFQjCNGR27UDvzJKXJmwcKKMOJtqGYyOlQ">Click here to submit your feedback</a></p> */}
        </div>
    )
}

export default Support;