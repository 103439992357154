import React from "react";
import firebaseApi from "../../apis/firebase/firebaseApi";
import Meeting from "./Meeting";
import arrayHelpers from "../../helpers/arrayHelpers";
import { UserContext } from "../../contexts/UserContext";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Meetings = () => {
    let { user } = React.useContext(UserContext);
    const [meetings, setMeetings] = React.useState([]);

    React.useEffect(() => {
        const unsub = firebaseApi.subscribeToMeetings(user.accountId, data => {
            let items = [];
            data.forEach(x => {
                let item = { ...x.data(), docId: x.id };
                items.push(item)
            });
            arrayHelpers.sort(items, "topic");
            setMeetings(items);
        });
        return () => unsub();
    }, [user.accountId]);

    return (
        <div>
            {
                meetings.length
                    ? <h2 className="background"><span>Active Meetings</span></h2>
                    : null
            }
            <div className="meetings-container">
                <TransitionGroup>
                    {meetings.map(m =>
                        <CSSTransition
                            key={m.docId}
                            timeout={1000}
                            classNames="item"
                        >
                            <Meeting key={m.docId} meeting={m} />
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </div>

        </div>
    )
}

export default Meetings;