const dateHelpers = {
    getScheduleDay: date => {
        let today = date || new Date();
        return `${dateHelpers.day(today.getDay())} ${today.getDate()}${dateHelpers.getSuffix(today.getDate())} ${dateHelpers.month(today.getMonth())}`
    },

    getTimeZoneOffset: () => {
        return new Date().getTimezoneOffset() * -60;
    },

    getDayOfTheWeek: day => {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return days[day.getDay()]
    },

    getTimeString: (date, includeAmPm) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let time = `${hours === 0 ? 12 : hours > 12 ? hours - 12 : hours}:${minutes < 10 ? "0" : ""}${minutes}`
        if (includeAmPm) {
            time = `${time} ${hours >= 12 ? "pm" : "am"}`;
        } 
        return time;
    },

    getTimestamp: () => {
        return Math.floor(Date.now() / 1000);
    }
}

export default dateHelpers;