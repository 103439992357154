const arrayHelpers = {
    getPropFromObject: function(obj, propString) {
        if (propString.indexOf(".") > 0) {
            let s = propString.split(".");
            let item = obj;
            for (let i = 0; i < s.length; i++) {
                item = item[s[i]];
            }
            return item;
        }
        return obj[propString];
    },
    sort: (array, prop) => {
        return array.sort((a,b) => {
            if (arrayHelpers.getPropFromObject(a, prop) > arrayHelpers.getPropFromObject(b, prop)) return 1;
            if (arrayHelpers.getPropFromObject(a, prop) < arrayHelpers.getPropFromObject(b, prop)) return -1;
            return 0;
        })
    }
}

export default arrayHelpers;