import React from "react";
import User from "../common/User";
import { UserContext } from "../../contexts/UserContext";
import firebaseApi from "../../apis/firebase/firebaseApi";
import arrayHelpers from "../../helpers/arrayHelpers";

const Search = props => {
    let { user } = React.useContext(UserContext);
    const [users, setUsers] = React.useState([]);

    React.useEffect(() => {
        firebaseApi.subscribeToUsers(user.accountId, data => {
            let items = [];
            data.forEach(x => {
                let item = x.data();
                if (item.active) {
                    items.push(item);
                }
            });
            arrayHelpers.sort(items, "name.full");
            setUsers(items);
        })
    }, []);

    const onTextChange = e => {
        props.onTextChange(e.target.value);
    }

    const onSearchClearClick = () => {
        props.onTextChange("");
        props.closeSearch();
    }

    let filteredUsers = users.filter(x => 
            (x.name && x.name.full && x.name.full.toLowerCase().includes(props.text.toLowerCase()))
            || (x.team && x.team.toLowerCase().includes(props.text.toLowerCase()))
        );

    return (
        <div className="search">
            <div className="search-box">
                <i className="fas fa-search search-icon" />
                <input
                    className="search-textbox"
                    onChange={onTextChange}
                    type="text"
                    placeholder="Search"
                    value={props.text}
                />
                {props.text.length > 0 && <i onClick={onSearchClearClick} className="fas fa-times search-close" />}
            </div>
            <div className="searchResultsContainer">
                {filteredUsers.map(u => <User key={u.id} user={u} showIcon={true} />)}
            </div>
        </div>
    )
}

export default Search;