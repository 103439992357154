import React from "react";

const AccountAuthorize = props => {
    const onAuthClick = () => {
        window.location = "https://zoom.us/oauth/authorize?response_type=code&client_id=toFDmn4T52i5ComM7D1Ag&redirect_uri=https://insideyourbusiness.com/account/auth";
    }
    return (
        <div className="content-center">
            <h3>Authorize the Zoom App</h3>
            <p>In order to fully use this application, an admin on your Zoom account must first authorize the backend app.</p>
            <div className="text-center">
                <button onClick={onAuthClick} className="btn">Authorize</button>
            </div>
        </div>
    )
}

export default AccountAuthorize;