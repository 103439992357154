const RouteEnum = {
    HOME: "/",
    AUTH: "/login/auth",
    LOGIN: "/login",
    LOGIN_TEST: "/login/test",

    APP: "/app",
    COMPANY: "/company",
    TEAMS: "/teams",
    MY_TEAM: "/myteam",
    SETTINGS: "/settings",
    LOGOUT: "/logout",

    FAQ: "/faq",
    SUPPORT: "/support",
    TERMS: "/terms",
    PRIVACY: "/privacy",
    LOGIN_DOC: "/login-doc",
    APP_DOC: "/app-doc",
    ACCOUNT_AUTHORIZE: "/account/authorize",
    ACCOUNT_SUCCESS: "/account/success"
}

export default RouteEnum;