import React from "react";
import StatusEnum from "../../models/Enums/StatusEnum";
import arrayHelpers from "../../helpers/arrayHelpers";
import { AccountContext } from "../../contexts/AccountContext";
import Card from "../common/Card";

const Statuses = () => {
    let { accountUsers } = React.useContext(AccountContext);

    const groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const groupStatuses = () => {
        let users = accountUsers.filter(item => 
            item.status
                    && StatusEnum[item.status]
                    && !StatusEnum[item.status].hide
                    && (!item.currentMeetings || !item.currentMeetings.length)
            );
        let groups = groupBy(users, "status");

        let statuses = StatusEnum;

        return Object.keys(groups).filter(g => g !== StatusEnum.OFFLINE.type).map(g => {
            return {
                status: g,
                name: statuses[Object.keys(statuses).filter(x => x === g)[0]].desc,
                isGrayedOut: statuses[g].isGrayedOut,
                participants: arrayHelpers.sort(groups[g], "name.full")
            }
        })
    }

    let statuses = groupStatuses();
    if (!statuses.length) return null;

    return (
        <>
            <h2><span>Team Member Status</span></h2>
            <div style={styles.meetingsContainer}>
                {
                    statuses.map(s => <Card key={s.status} meeting={s} />)
                }
            </div>
        </>
    )
}

const styles = {
    meetingsContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    }
}

export default Statuses;