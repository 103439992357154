import React from "react";
import { Switch, Route } from "react-router-dom";

import RouteEnum from "../models/Enums/RouteEnum";
import Auth from "../components/login/Auth";
import Login from "../components/login/Login";
import Support from "../components/support/Support";
import Terms from "../components/support/Terms";
import Privacy from "../components/support/Privacy";
import Home from "../components/home/Home";
import UnauthenticatedHeader from "../components/header/UnauthenticatedHeader";
import AppDoc from "../components/support/AppDoc";
import LoginDoc from "../components/support/LoginDoc";
import AccountAuthorize from "../components/support/AccountAuthorize";
import LoginTest from "../components/login/LoginTest";
import AccountSuccess from "../components/support/AccountSuccess";

const UnauthenticatedRouter = () => {
    return (
        <>
            <UnauthenticatedHeader />
            <Switch>
                <Route exact={true} path={RouteEnum.HOME} component={Home} />
                <Route exact={true} path={RouteEnum.AUTH} component={Auth} />
                <Route exact={true} path={RouteEnum.SUPPORT} component={Support} />
                <Route exact={true} path={RouteEnum.TERMS} component={Terms} />
                <Route exact={true} path={RouteEnum.PRIVACY} component={Privacy} />
                <Route exact={true} path={RouteEnum.APP_DOC} component={AppDoc} />
                <Route exact={true} path={RouteEnum.LOGIN_DOC} component={LoginDoc} />
                <Route exact={true} path={RouteEnum.ACCOUNT_AUTHORIZE} component={AccountAuthorize} />
                <Route exact={true} path={RouteEnum.ACCOUNT_SUCCESS} component={AccountSuccess} />
                <Route exact={true} path={RouteEnum.LOGIN} component={Login} />
                <Route exact={true} path={RouteEnum.LOGIN_TEST} component={LoginTest} />
                
                <Route component={Home} />
            </Switch>
        </>
    )
};

export default UnauthenticatedRouter;