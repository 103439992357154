import React from "react";
import firebaseApi from "../../apis/firebase/firebaseApi";
import RouteEnum from "../../models/Enums/RouteEnum";

const Logout = () => {
    React.useEffect(() => {
        firebaseApi.logout(() => {
            window.location = RouteEnum.HOME;
        })
    })
    return null;
}

export default Logout;