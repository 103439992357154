import React from "react";
import { UserContext } from "../../contexts/UserContext";
import RouteEnum from "../../models/Enums/RouteEnum";
import HeaderMenuItem from "./HeaderMenuItem";
import useHover from "../../hooks/useHover";
import StatusDropdown from "./StatusDropdown";
import { useHistory } from "react-router-dom";
import StatusEnum from "../../models/Enums/StatusEnum";
import StartMeetingModal from "./StartMeetingModal";
import firebaseApi from "../../apis/firebase/firebaseApi";
import dateHelpers from "../../helpers/dateHelpers";
import config from "../../apis/firebase/config";

const Header = props => {
    let { user } = React.useContext(UserContext);
    const [searchText, setSearchText] = React.useState("");
    const [showStatusDropdown, setShowStatusDropdown] = React.useState(false);
    const [showStartMeetingModal, setShowStartMeetingModal] = React.useState(false);
    const [showMobileMenu, setShowMobileMenu] = React.useState(false);
    let history = useHistory();

    let [profilePicRef, isHovered] = useHover();

    const onLogoClick = () => {
        history.push(RouteEnum.HOME);
        onSearchClearClick();
    }

    const getIcon = () => {
        if (!user) return null;
        let matchedStatus = Object.keys(StatusEnum).filter(x => x === user.status);
        if (!matchedStatus || !matchedStatus.length || StatusEnum[matchedStatus[0]].hideIcon) {
            return null;
        }
        return <img src={`/icons/${matchedStatus[0].toLowerCase()}.png`} style={styles.profileIcon} />;
    }

    const getNoIcon = () => {
        if (!user) return null;
        let matchedStatus = Object.keys(StatusEnum).filter(x => x === user.status);
        if (!matchedStatus || !matchedStatus.length) {
            return null;
        }
        return <img className="icon" src={`/icons/no/${matchedStatus[0].toLowerCase()}.png`} />;
    }

    const getStatusDesc = () => {
        if (!user) return null;
        let matchedStatus = Object.keys(StatusEnum).filter(x => x === user.status);
        if (!matchedStatus || !matchedStatus.length) {
            return null;
        }
        return StatusEnum[matchedStatus[0]].desc;
    }

    const onStartMeetingClick = () => {
        setShowStartMeetingModal(true);
        //window.open(user.personal_meeting_url, "_blank");
    }

    const onStartMeetingClose = () => {
        setShowStartMeetingModal(false);
    }

    const onCloseStatusDropdown = () => {
        setShowStatusDropdown(false);
        isHovered = false;
        //profilePicRef.current;
    }

    const onStatusBackClick = () => {
        let status = user.currentMeetings && user.currentMeetings.length
            ? StatusEnum.IN_MEETING.type
            : StatusEnum.ONLINE.type;

        config.analytics.logEvent("status_changed", {status: status, imBackButton: true});
        firebaseApi.updateUser(user.accountId, user.id, { status: status, timestampStatus: dateHelpers.getTimestamp() }, () => { });
    }

    const showStatusBackButton = () => {
        if (!user) return false;
        return user.status !== StatusEnum.ONLINE.type && user.status !== StatusEnum.IN_MEETING.type;
    }

    const onSearchTextChange = e => {
        setSearchText(e.target.value);
        if (props.onSearchChange) {
            props.onSearchChange(e.target.value);
        }
    }

    const onSearchClearClick = () => {
        setSearchText("");
        setShowMobileMenu(false);
        if (props.onSearchChange) {
            props.onSearchChange("");
        }
    }

    const onDialInClick = () => {
        window.open("https://zoom.us/zoomconference?m=12uppBSRbd68AK4HRyiVV9zB4O3DS4Tp", "_blank");
    }

    const onHeaderMenuClick = () => {
        setShowMobileMenu(true);
    }

    const onStatusButtonClick = () => {
        setShowStatusDropdown(!showStatusDropdown);
    }

    const onSearchClick = () => {
        props.onShowSearch();
    }

    return (
        <>
            <div className="header-container">
                <div>
                    <div className="header-icons-menu">
                        <i onClick={onHeaderMenuClick} className="far fa-bars" />
                    </div>
                    <div className="header-icons">
                        <HeaderMenuItem
                            route={RouteEnum.COMPANY}
                            iconClass="fas fa-building"
                            name="Company"
                            onClick={onSearchClearClick}
                        />
                        <HeaderMenuItem
                            route={RouteEnum.TEAMS}
                            iconClass="fas fa-users-class"
                            name="Teams"
                            onClick={onSearchClearClick}
                        />
                        <HeaderMenuItem
                            route={RouteEnum.MY_TEAM}
                            iconClass="fas fa-users-crown"
                            name="My Team"
                            onClick={onSearchClearClick}
                        />
                        <HeaderMenuItem
                            route={RouteEnum.FAQ}
                            iconClass="fas fa-question-circle"
                            name="FAQ"
                            onClick={onSearchClearClick}
                        />
                        <HeaderMenuItem
                            route={RouteEnum.SETTINGS}
                            iconClass="fas fa-cog"
                            name="Settings"
                            onClick={onSearchClearClick}
                        />
                        <HeaderMenuItem
                            iconClass="fas fa-phone-office"
                            name="Int. Dial-In"
                            onClick={onDialInClick}
                        />
                    </div>
                    <div><img className="logo" onClick={onLogoClick} src="/IYB.png" /></div>
                    <div className="right-menu-search-icon">
                        {
                            props.showSearch
                                ? <i onClick={onSearchClick} className="fas fa-times" />
                                : <i onClick={onSearchClick} className="fas fa-search" />
                        }

                    </div>
                    <div className="right-menu">
                        <div>
                            <div style={styles.searchContainer}>
                                <i style={styles.searchIcon} className="fas fa-search" />
                                <input
                                    style={styles.searchBox}
                                    onChange={onSearchTextChange}
                                    type="text"
                                    placeholder="Search"
                                    value={searchText}
                                />
                                {searchText.length > 0 && <i onClick={onSearchClearClick} style={styles.closeIcon} className="fas fa-times" />}
                            </div>
                        </div>
                        <div>
                            <div onClick={onStartMeetingClick} className="start-meeting-button" style={styles.startMeetingButton}>Start Meeting</div>
                        </div>

                        {showStatusBackButton() && <div><div onClick={onStatusBackClick} className="status-back-button">I'm Back</div></div>}

                        <div style={styles.profilePicContainer} ref={profilePicRef}>
                            {
                                user && user.picUrl
                                    ? <img style={styles.profilePic} src={user.picUrl} />
                                    : <i className="fas fa-user-circle" />
                            }
                            {getIcon()}
                            <StatusDropdown show={isHovered} onClick={onCloseStatusDropdown} />
                        </div>
                    </div>
                </div>
                <div className="header-line-2">
                    <div className="my-room-btn">
                        <button onClick={onStartMeetingClick} type="button" className="btn btn-ok">My Room <i className="far fa-chevron-down" /></button>
                    </div>
                    <div className="status-btn" onClick={onStatusButtonClick}>
                        <div>
                            {getNoIcon()}
                            {getStatusDesc()}
                            <i className="far fa-chevron-down" />
                            <div className="profile-container">
                                {
                                    user && user.picUrl
                                        ? <img className="profile-pic" src={user.picUrl} />
                                        : <i className="fas fa-user-circle" />
                                }
                            </div>
                            <StatusDropdown show={showStatusDropdown} onClick={onCloseStatusDropdown} />
                        </div>
                    </div>
                </div>
            </div>
            <StartMeetingModal show={showStartMeetingModal} onClose={onStartMeetingClose} />
            {
                showMobileMenu &&
                <div className="header-menu">
                    <div className="menu">
                        <i className="far fa-times" onClick={onSearchClearClick} />
                        <HeaderMenuItem
                            route={RouteEnum.COMPANY}
                            iconClass="fas fa-building"
                            name="Company"
                            onClick={onSearchClearClick}
                        />
                        <HeaderMenuItem
                            route={RouteEnum.TEAMS}
                            iconClass="fas fa-users-class"
                            name="Teams"
                            onClick={onSearchClearClick}
                        />
                        <HeaderMenuItem
                            route={RouteEnum.MY_TEAM}
                            iconClass="fas fa-users-crown"
                            name="My Team"
                            onClick={onSearchClearClick}
                        />
                        <HeaderMenuItem
                            route={RouteEnum.FAQ}
                            iconClass="fas fa-question-circle"
                            name="FAQ"
                            onClick={onSearchClearClick}
                        />
                        <HeaderMenuItem
                            route={RouteEnum.SETTINGS}
                            iconClass="fas fa-cog"
                            name="Settings"
                            onClick={onSearchClearClick}
                        />
                        <HeaderMenuItem
                            iconClass="fas fa-phone-office"
                            name="Int. Dial-In"
                            onClick={onDialInClick}
                        />
                    </div>
                </div>
            }
        </>
    );
}

const styles = {
    headerContainer: {
        position: "relative",
        height: "100px",
        background: "#fff",
        width: "100%",
        textAlign: "center"
    },
    headerIcons: {
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        top: "0",
        left: "10px",
        paddingTop: "10px"
    },
    iconContainer: {
        display: "flex",
        flexDirection: "column",
        fontSize: "14px",
        padding: "17px 30px",
        color: "#9F9F9F",
        cursor: "pointer"
    },
    logo: {
        cursor: "pointer",
        paddingTop: "22px",
        height: "80px",
        userSelect: "none"
    },
    icon: {
        fontSize: "32px"
    },
    rightMenu: {
        position: "absolute",
        top: "0",
        right: "0",
        display: "flex",
        //alignItems: "center"
    },
    profilePic: {
        width: "70px",
        //padding: "15px",
        borderRadius: "50%"
    },
    profilePicContainer: {
        position: "relative",
        fontSize: "60px",
        color: "#666",
        padding: "15px"
    },
    profileIcon: {
        position: "absolute",
        bottom: "25px",
        right: "-5px"
    },
    startMeetingButton: {
        background: "linear-gradient(30.73deg, #2F931D 16.02%, #47C131 84.3%)",
        borderRadius: "30px",
        color: "#fff",
        fontWeight: "800",
        fontSize: "16px",
        padding: "10px 16px",
        marginTop: "27px",
        cursor: "pointer"
    },
    searchContainer: {
        position: "relative",
        marginTop: "30px",
        marginRight: "10px"
    },
    searchIcon: {
        position: "absolute",
        top: "11px",
        left: "10px",
        color: "#666"
    },
    searchBox: {
        paddingLeft: "32px"
    },
    closeIcon: {
        position: "absolute",
        top: "11px",
        right: "10px",
        color: "#666",
        cursor: "pointer"
    }
}

export default Header;