import React from "react";
import Favorites from "../common/Favorites";
import arrayHelpers from "../../helpers/arrayHelpers";
import { AccountContext } from "../../contexts/AccountContext";
import Card from "../common/Card";

const Teams = () => {
    let {accountUsers} = React.useContext(AccountContext);

    const groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    const groupTeams = () => {
        let users = accountUsers.filter(x => x.team);
        let groups = groupBy(users, "team");

        let list = Object.keys(groups).map(g => {
            return {
                team: g,
                name: g,
                participants: arrayHelpers.sort(groups[g], "name.full")
            }
        })

        return arrayHelpers.sort(list, "team");
    }

    return (
        <>
            <Favorites />
            <h2 className="background"><span>All Team Members</span></h2>
            <div style={styles.meetingsContainer}>
                {groupTeams().map(t => <Card meeting={t} />)}
            </div>
        </>
    );
}

const styles = {
    meetingsContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    }
}

export default Teams;