import React from "react";
import { UserContext } from "../../contexts/UserContext";
import { AccountContext } from "../../contexts/AccountContext";
import arrayHelpers from "../../helpers/arrayHelpers";
import StatusEnum from "../../models/Enums/StatusEnum";
import InMeeting from "./InMeeting";
import Favorites from "../common/Favorites";
import Card from "../common/Card";


const MyTeam = () => {
    let { user } = React.useContext(UserContext);
    let { accountUsers } = React.useContext(AccountContext);

    let users = accountUsers.filter(x => x.team === user.team);

    let myteam = {
        team: user.team,
        name: user.team,
        participants: arrayHelpers.sort(users.filter(x => x.status !== StatusEnum.IN_MEETING.type), "name.full")
    };

    let usersInMeeting = users.filter(x => x.currentMeetings && x.currentMeetings.length);

    return (
        <div style={{ height: "100%" }}>
            <Favorites />
            <h2 className="background"><span>My Team</span></h2>
            {
                user.team
                    ?
                    <>
                        <div className="my-team-container">
                            <Card meeting={myteam} showLinks={true} />
                        </div>
                        <div className="my-team-user-container">
                            {usersInMeeting.map(u => <InMeeting key={u.id} user={u} showIcon={true} />)}
                        </div>
                    </>
                    :
                    <>
                        <div className="text-center">Looks like you don't have a team set.</div>
                        <div className="mt-20 text-center">Head over to the <a href="/settings">Settings</a> page to set your team.</div>
                    </>
            }
        </div>
    );
}

export default MyTeam;