import React from "react";

const LoginTest = () => {

    React.useEffect(()=> {
        const redirectUri = `${window.location.origin}/login/auth`;

        const clientId = "ufkEUpZnSu2x3dNNK5MFw"; //InsideYourBusiness
        //const clientId = "UwQTtapZT1WQzBk9VuUrRA"; //Powerfront
        //const clientId = "75jt6bKRTNe7VJdfnthjpQ"; //DC

        window.location = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${clientId}`;
    })
    
    return null;
}

export default LoginTest;