import React from "react";
import Carousel from "react-spring-3d-carousel";
import './home.scss';

const Home = () => {
    const [activeSlide, setActiveSlide] = React.useState(0);

    const slides = [
        {
            key: 1,
            content: <img src="/home/carousel-1.jpg" alt="carousel image 1" />
        },
        {
            key: 2,
            content: <img src="/home/carousel-2.jpg" alt="carousel image 2" />
        },
        {
            key: 3,
            content: <img src="/home/carousel-3.jpg" alt="carousel image 3" />
        },
        {
            key: 4,
            content: <img src="/home/carousel-4.jpg" alt="carousel image 4" />
        }
    ];

    const slideTitles = [
        'See what’s happening in the company at a glance',
        'Status updates for team visibility',
        'See all team members and departments',
        'Perfect for all teams working remotely, local and global',
    ];

    const carouselNavClick = (e) => {
        setActiveSlide(parseInt(e.target.getAttribute('value')))
    }

    const goToPrevSlide = (e) => {
        setActiveSlide(activeSlide - 1);
    }

    const goToNextSlide = (e) => {
        setActiveSlide(activeSlide + 1);
    }

    const getCarouselNavigation = () => {
        return (
            <div className="carousel-nav">
                <button className="prev-button" onClick={goToPrevSlide}>previous</button>
                <button className="next-button" onClick={goToNextSlide}>next</button>
                <ul>
                    {slides.map((s, i) => <li className={i === getActiveSlideIndex() ? 'active' : ''} key={i} onClick={carouselNavClick} value={i}>go to slide {i}</li>)}
                </ul>
            </div>
        );
    }

    const getActiveSlideIndex = () => {
        return Math.abs(activeSlide % slides.length);
    }

    return (
        <div className="home">
            <div className="banner">
                <img className="desktop-only" src="/home/img-hero.jpg" alt="banner image" />
                <img className="mobile-only" src="/home/mobile-img-hero.jpg" alt="banner image" />
            </div>
            <div className="grey-box">
                <img src="/home/img-2.png" alt="second banner image" />
            </div>
            <div className="flex-box visibility">
                <h2>Visibility</h2>
                <p>See your remote office as if it’s a physical one. Team members or departments each can have an “office” or meeting room they can work in. Need to find a co-worker in a pinch? No need to wander the building searching. Simply check your team dashboard to find out where they are. Ensure your remote employees are engaged, alert, and intent on moving your business forward.</p>
            </div>
            <div className="flex-box connection">
                <h2>Connection</h2>
                <p>Encourage collaboration and communication. Lack of visibility can lead to feelings of isolation, which are prohibitive to a cohesive and connected workforce. Encourage <span className="no-wrap">face-to-face</span> meetings with daily catch-ups among direct reports or company-wide staff meetings. Plan office-wide team-building activities and foster your company culture, all through Zoom.</p>
            </div>
            <div className="flex-box access">
                <h2>Ease of Access</h2>
                <p>Make scheduling internal or external meetings a breeze. Start or join a meeting with a single click. Seamlessly create meeting invitations and impromptu brainstorm sessions. Move from one office <span className="no-wrap">to another</span> within your customized visual organization. Set your status to “away,” “lunch break,” “on vacation,” or more to let your team know when you’re away from your desk. </p>
            </div>
            <div className="carousel-box">
                <h3>{slideTitles[getActiveSlideIndex()]}</h3>
                <div className="carousel-container">
                    <Carousel goToSlide={activeSlide} slides={slides} />
                </div>
                {getCarouselNavigation()}
            </div>
            <div className="link-box">
                <div>
                    <div className="badge-container desktop-only">
                        <img src="/home/iyb-badge.png" alt="" />
                    </div>
                    <div className="link-box-button">
                        <a className="btn-link" href="https://zoom.us/oauth/authorize?response_type=code&client_id=toFDmn4T52i5ComM7D1Ag&redirect_uri=https://insideyourbusiness.com/account/auth">Get Started</a>
                    </div>
                </div>
                <div className="text-container">
                    <h3>Getting started is free and easy</h3>
                    <div>
                        <span>Follow the checklist to ensure you will be eligible to use the INSIDE Your Business Zoom app.</span>
                        <ol>
                            <li>Your Zoom account administrator must authorize the app</li>
                            <li>Install the INSIDE Your Business app via the Zoom Marketplace</li>
                            <li>Login using your Company Zoom credentials</li>
                            <li>Bookmark the INSIDE Your Business page for quick reference for all future meetings</li>
                        </ol>
                    </div>
                </div>
            </div>
            {/* <img src="/home/from-powerfront.png" alt="from powerfront with love" /> */}
            <footer></footer>
        </div >
    )
}

export default Home;