import React from "react";

const Privacy = () => {
    return (
        <div className="content-center">
            <h2>PRIVACY POLICY</h2>
<p>Last updated April 13, 2020</p>



Thank you for choosing to be part of our community at Powerfront Inc (“Company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at support@insideyourbusiness.com.
<br /><br />
When you visit our website https://insideyourbusiness.com, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Sites and our services.
<br /><br />
This privacy policy applies to all information collected through our website (such as https://insideyourbusiness.com), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Services").
<br /><br />
Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.





<h3>1. WHAT INFORMATION DO WE COLLECT?</h3>


<h6>
    Personal information you disclose to us
</h6>
In Short:  We collect personal information that you provide to us.
<br /><br />
We collect personal information that you voluntarily provide to us when expressing an interest in obtaining information about us or our products and services, when participating in activities on the Services (such as posting messages in our online forums or entering competitions, contests or giveaways) or otherwise contacting us.
<br /><br />
The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect can include the following:
<br /><br />
Publicly Available Personal Information. We collect first name, maiden name, last name, and nickname; ID; business email; and other similar data. 
<br /><br />
Personal Information Provided by You. We collect app usage; and other similar data.
<br /><br />
All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.



<h6>
    Information collected from other sources
</h6>

In Short:   We may collect limited data from public databases, marketing partners, and other outside sources.
<br /><br />
We may obtain information about your from other sources, such as public databases, joint marketing partners, as well as from other third parties. Examples of the information we receive from other sources include: social media profile information; marketing leads and search results and links, including paid listings (such as sponsored links). We will inform you about the source of information and the type of information and the type of information we have collected about you within a reasonable period after obtaining the personal data, but at the latest within one month.



<h3>
    2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
</h3>

In Short:  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
<br /><br />
We may process or share data based on the following legal basis:
Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose.
<br /><br />
Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.
<br /><br />
Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
<br /><br />
Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
<br /><br />
Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
More specifically, we may need to process your data or share your personal information in the following situations:

<br /><br /><br /><br />
Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
<br /><br />
Third-Party Advertisers. We may use third-party advertising companies to serve ads when you visit the Services. These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you.
<br /><br />
Other Users. When you share personal information or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly distributed outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.


<h3>
    3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
</h3>
In Short:  We may use cookies and other tracking technologies to collect and store your information.
<br /><br />
We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.



<h3>
    4. HOW LONG DO WE KEEP YOUR INFORMATION?
</h3>

In Short:  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.
<br /><br />
We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than 6 months.
<br /><br />
When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
<br /><br />
<b>Zoom Data Retention</b>
<br />INSIDE Your Business collects your Zoom account data when you authorize the app.  How long we retain your Personal Data depends on the type of data and the purpose for which we process the data. We will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Notice unless a longer retention period is required or permitted by law.


<h3>
    5. HOW DO WE KEEP YOUR INFORMATION SAFE?
</h3>

In Short:  We aim to protect your personal information through a system of organizational and technical security measures.
<br /><br />
We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the services within a secure environment.



<h3>
    6. DO WE COLLECT INFORMATION FROM MINORS?
</h3>

In Short:  We do not knowingly collect data from or market to children under 18 years of age.
<br /><br />
We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at support@insideyourbusiness.com.



<h3>
    7. WHAT ARE YOUR PRIVACY RIGHTS?
</h3>

In Short:  In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
<br /><br />
In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.
<br /><br />
If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.
<br /><br />
If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
<br /><br />
Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt-out of interest-based advertising by advertisers on our Services visit http://www.aboutads.info/choices/.



<h3>
    8. DATA BREACH
</h3>

A privacy breach occurs when there is unauthorized access to or collection, use, disclosure or disposal of personal information. You will be notified about data breaches when Powerfront Inc believes you are likely to be at risk or serious harm. For example, a data breach may be likely to result in serious financial harm or harm to your mental or physical well-being. In the event that Powerfront Inc becomes aware of a security breach which has resulted or may result in unauthorized access, use or disclosure of personal information Powerfront Inc will promptly investigate the matter and notify the applicable Supervisory Authority not later than 72 hours after having become aware of it, unless the personal data breach is unlikely to result in a risk to the rights and freedoms of natural persons.



<h3>
    9. CONTROLS FOR DO-NOT-TRACK FEATURES
</h3>

Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.



<h3>
    10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
</h3>

In Short:  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
<br /><br />
California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
<br /><br />
If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from our systems.



<h3>
    11. DO WE MAKE UPDATES TO THIS POLICY?
</h3>

In Short:  Yes, we will update this policy as necessary to stay compliant with relevant laws.
<br /><br />
We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.



<h3>
    12. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
</h3>

If you have questions or comments about this policy, you may email us at support@insideyourbusiness.com or by post to:
<br /><br />
Powerfront Inc <br />
468 North Camden Drive #200<br />
Beverly Hills, CA 90210<br />
United States


<h4>
    HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
</h4>

Based on the laws of some countries, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by clicking here. We will respond to your request within 30 days.
        </div>
    )
}

export default Privacy;