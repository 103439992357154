const StatusEnum = {
    ONLINE: {type: "ONLINE", desc: "Online", hide: true, hideIcon: true, dropdownClass: "ok"},
    OFFLINE: {type: "OFFLINE", desc: "Offline", isGrayedOut: true},
    SHORT_BREAK: {type: "SHORT_BREAK", desc: "Short Break"},
    LUNCH_BREAK: {type: "LUNCH_BREAK", desc: "Lunch Break"},
    TIME_OFF: {type: "TIME_OFF", desc: "Time Off"},
    WORK_TRAVEL: {type: "WORK_TRAVEL", desc: "Work Travel"},
    SICK_LEAVE: {type: "SICK_LEAVE", desc: "Sick Leave", isGrayedOut: true},
    EXTERNAL_CALL: {type: "EXTERNAL_CALL", desc: "External Call", dropdownClass: "danger"},
    FOCUSING: {type: "FOCUSING", desc: "Focusing / DND", dropdownClass: "danger"},
    UNAVAILABLE: {type: "UNAVAILABLE", desc: "Unavailable", isGrayedOut: true, dropdownClass: "danger"},
    IN_MEETING: {type: "IN_MEETING", desc: "In Meeting", hide: true, hideInDropdown: true, dropdownClass: "danger"},
    NO_SHOW: {type: "NO_SHOW", desc: "No Show", hideInDropdown: true, isGrayedOut: true, dropdownClass: "danger"}
}

export default StatusEnum;