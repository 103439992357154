import React from "react";

const AppDoc = () => {
    return (
        <div className="content-center">
            <h2>INSIDE Your Business Documentation</h2>

            <h3 className="text-left">Installation</h3>
            <p><b>Prerequisite:</b> In order to use this app, an admin for your Zoom account must follow these steps:
                <ul>
                    <li>Visit the INSIDE Your Business <a href="/account/authorize">Zoom Authorization Page</a> and click the Authorize button.</li>
                    <li>A user with <b>Administrator Rights</b> to your Zoom account must log in and authorize INSIDE Your Business.</li>
                    <li>
                        Once an Administrator has authorized the application to interact with your Zoom account, additional users can log in by going directly 
                        to <a href="/">INSIDE Your Business</a>.
                    </li>

                </ul>
            </p>

            <h3 className="text-left mt-40">Usage</h3>
            <p>Once you've logged into the app, you will see your organization's current meetings and teams.</p>

            <p><b>Company Page</b> gives you an at a glance view of the people in your organization's availability.  Active meetings are displayed at the top, 
            followed by listings of users by status and department.</p>

            <p><b>Teams Page</b> provides a breakdown by team of every individual in the organization regardless of availability.</p>

            <p><b>My Team Page</b> shows a listing of all members of your team, including a listing of active meetings they are in.</p>

            <p><b>Settings Page</b> gives you a means to specify your team, normal working hours, and preferred view for INSIDE Your Business.</p>
            
            <h3 className="text-left mt-40">Uninstallation</h3>
            <p>To deactivate this application, an admin for your Zoom account should follow this process:
                <ul>
                    <li>Visit the <a href="https://marketplace.zoom.us">Zoom App Marketplace.</a></li>
                    <li>Use the <b>Manage</b> button in the upper-right corner of the screen.</li>
                    <li>Click on <b>Installed Apps</b> from the left side of the screen.</li>
                    <li>Select INSIDE Your Business and click <b>Uninstall</b>.</li>

                </ul>
            </p>

            <h3 className="text-left mt-40">Support</h3>
            <p>For any questions, comments, concerns, please visit our <a href="/support">Support</a> page.</p>
            
            <p></p>
        </div>
    )
}

export default AppDoc;